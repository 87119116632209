// Usuarios.jsx
import React, { useState, useEffect } from "react";
import styles from "./Usuarios.module.css";
import SearchBar from "../SearchBar/SearchBar";
import { useSelector } from "react-redux";
import {calcularEdad, formFecha} from "../../funciones";

const Usuarios = ({
  activarUsuario,
  desactivarUsuario,
  inVisitor,
  allUsuarios,
  eliminarUsuario
}) => {
  const [filteredJugadores, setFilteredJugadores] = useState([]);
  const [order, setOrder] = useState("asc");
  const [selectedRol, setSelectedRol] = useState("todos");
  const [selectedEstado, setSelectedEstado] = useState("todos");
  const [selectedEstadoRegion, setSelectedEstadoRegion] = useState("todos");
  const [searchTerm, setSearchTerm] = useState("");
  const [updateCount, setUpdateCount] = useState(0);
  const [edadRange, setEdadRange] = useState({ min: 0, max: 100 });
  const [modalVisible, setModalVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  // Función de ordenamiento
  const orderList = (list) => {
    if (!list || !Array.isArray(list)) return [];
    
    return [...list].sort((a, b) => {
      const nameA = (a.nombre || '').toLowerCase();
      const nameB = (b.nombre || '').toLowerCase();
      
      return order === "asc" 
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    });
  };

  const activar = async (id) => {
    await activarUsuario(id);
    setUpdateCount(updateCount + 1);
  };

  const desactivar = async (id) => {
    await desactivarUsuario(id);
    setUpdateCount(updateCount + 1);
  };

  // Función para aplicar todos los filtros y ordenamiento
  const applyFilters = (search, rol, estado, estadoRegion, edadRango, currentOrder = order) => {
    let filtered = [...allUsuarios];

    console.log('Filtros aplicados:', { search, rol, estado, estadoRegion, edadRango });
    console.log('Usuarios iniciales:', filtered.length);

    // Aplicar filtro de edad
    if (edadRango.min > 0 || edadRango.max < 100) {
      filtered = filtered.filter((user) => {
        if (user.dia && user.mes && user.anio) {
          const edad = calcularEdad(user.dia, user.mes, user.anio);
          return edad >= edadRango.min && edad <= edadRango.max;
        }
        return false;
      });
      console.log('Después de filtro edad:', filtered.length);
    }

    // Aplicar filtro de estado de registro
    if (estado && estado !== "todos") {
      filtered = filtered.filter((user) => {
        const isCompleted = user.activo === true;
        const matchesEstado = estado === "completado" ? isCompleted : !isCompleted;
        return matchesEstado;
      });
      console.log('Después de filtro estado registro:', filtered.length);
    }

    // Aplicar filtro de estado/región
    if (estadoRegion && estadoRegion !== "todos") {
      filtered = filtered.filter((user) => user.origen === estadoRegion);
      console.log('Después de filtro estado región:', filtered.length);
    }

    // Aplicar filtro de rol
    if (rol && rol !== "todos") {
      filtered = filtered.filter((user) => {
        const matchesRol = user.rol === rol;
        return matchesRol;
      });
      console.log('Después de filtro rol:', filtered.length);
    }

    // Aplicar filtro de búsqueda
    if (search) {
      filtered = filtered.filter((user) =>
        user.nombre?.toLowerCase().includes(search.toLowerCase()) ||
        user.correo?.toLowerCase().includes(search.toLowerCase()) ||
        user.id?.toLowerCase().includes(search.toLowerCase())
      );
      console.log('Después de búsqueda:', filtered.length);
    }

    return orderList(filtered);
  };

  const handleRolFilter = (rol) => {
    console.log('Cambiando rol a:', rol);
    setSelectedRol(rol);
    // Aplicar filtros manteniendo el estado actual
    const filteredResults = applyFilters(searchTerm, rol, selectedEstado, selectedEstadoRegion, edadRange);
    setFilteredJugadores(filteredResults);
  };

  const handleEstadoFilter = (estado) => { // Modificado para recibir solo el estado
    console.log('Cambiando estado a:', estado, 'con rol actual:', selectedRol);
    setSelectedEstado(estado);
    // Usar el rol actual del estado
    const filteredResults = applyFilters(searchTerm, selectedRol, estado, selectedEstadoRegion, edadRange);
    setFilteredJugadores(filteredResults);
  };

  const handleEstadoRegionFilter = (estadoRegion) => {
    console.log('Cambiando estado/región a:', estadoRegion);
    setSelectedEstadoRegion(estadoRegion);
    const filteredResults = applyFilters(searchTerm, selectedRol, selectedEstado, estadoRegion, edadRange);
    setFilteredJugadores(filteredResults);
  };

  const handleEdadFilter = (min, max) => {
    console.log('Cambiando rango de edad a:', { min, max });
    setEdadRange({ min, max });
    const filteredResults = applyFilters(
      searchTerm, 
      selectedRol, 
      selectedEstado, 
      selectedEstadoRegion, 
      { min, max }
    );
    setFilteredJugadores(filteredResults);
  };

  const handleJugadoresSearch = (search) => {
    setSearchTerm(search);
    setFilteredJugadores(applyFilters(search, selectedRol, selectedEstado, selectedEstadoRegion, edadRange));
  };

  const handleResetJugadores = () => {
    setSearchTerm("");
    setSelectedRol("todos");
    setSelectedEstado("todos");
    setSelectedEstadoRegion("todos");
    setEdadRange({ min: 0, max: 100 });
    setFilteredJugadores(orderList(allUsuarios));
  };

  const toggleOrder = () => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    // Reaplicar filtros con el nuevo orden
    setFilteredJugadores(currentList => {
      return [...currentList].sort((a, b) => {
        const nameA = (a.nombre || '').toLowerCase();
        const nameB = (b.nombre || '').toLowerCase();
        return newOrder === "asc" 
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    });
  };

  // Efecto inicial
  useEffect(() => {
    if (allUsuarios && allUsuarios.length > 0) {
      setFilteredJugadores(orderList(allUsuarios));
    }
  }, []);

  // Efecto para cuando cambian los usuarios
  useEffect(() => {
    if (allUsuarios) {
      const filteredResults = applyFilters(
        searchTerm, 
        selectedRol, 
        selectedEstado, 
        selectedEstadoRegion, 
        edadRange
      );
      setFilteredJugadores(filteredResults);
    }
  }, [allUsuarios, searchTerm, selectedRol, selectedEstado, selectedEstadoRegion, edadRange, order]);

  // Agregar función para limpiar filtros individuales
  const handleClearFilter = (filterType) => {
    switch (filterType) {
      case 'search':
        setSearchTerm("");
        setFilteredJugadores(applyFilters("", selectedRol, selectedEstado, selectedEstadoRegion, edadRange));
        break;
      case 'rol':
        setSelectedRol("todos");
        setFilteredJugadores(applyFilters(searchTerm, "todos", selectedEstado, selectedEstadoRegion, edadRange));
        break;
      case 'estado':
        setSelectedEstado("todos");
        setFilteredJugadores(applyFilters(searchTerm, selectedRol, "todos", selectedEstadoRegion, edadRange));
        break;
      case 'region':
        setSelectedEstadoRegion("todos");
        setFilteredJugadores(applyFilters(searchTerm, selectedRol, selectedEstado, "todos", edadRange));
        break;
      case 'edad':
        const defaultEdadRange = { min: 0, max: 100 };
        setEdadRange(defaultEdadRange);
        setFilteredJugadores(applyFilters(searchTerm, selectedRol, selectedEstado, selectedEstadoRegion, defaultEdadRange));
        break;
      default:
        break;
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      await eliminarUsuario(userToDelete.correo);
      setModalVisible(false);
      setUserToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setModalVisible(false);
    setUserToDelete(null);
  };

  return (
    <div className={styles.container}>
      {modalVisible && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h3 className={styles.modalTitle}>Confirmar Eliminación</h3>
            <p className={styles.modalText}>
              ¿Estás seguro que deseas eliminar al usuario{" "}
              <span className={styles.modalHighlight}>{userToDelete?.nombre}</span>?
            </p>
            <p className={styles.modalWarning}>
              Esta acción es irreversible y eliminará todos los datos asociados al usuario.
            </p>
            <div className={styles.modalButtons}>
              <button 
                className={styles.modalCancelBtn}
                onClick={handleCancelDelete}
              >
                Cancelar
              </button>
              <button 
                className={styles.modalConfirmBtn}
                onClick={handleConfirmDelete}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={styles.listas}>
        <div className={styles.listaUsuarios}>
          <h2 className={styles.title}>Administrar Usuarios</h2>
          <SearchBar
            handleSearch={handleJugadoresSearch}
            handleReset={handleResetJugadores}
            handleOrder={toggleOrder}
            handleRolFilter={handleRolFilter}
            handleEstadoFilter={handleEstadoFilter}
            handleEstadoRegionFilter={handleEstadoRegionFilter}
            handleEdadFilter={handleEdadFilter}
            order={order}
            selectedRol={selectedRol}
            selectedEstado={selectedEstado}
            selectedEstadoRegion={selectedEstadoRegion}
            edadRange={edadRange}
            handleClearFilter={handleClearFilter}
          />
          <div className={styles.lista}>
            {filteredJugadores.map((user) => (
              <div className={styles.user} key={user.id}>
                <div className={styles.info}>
                  <div className={styles.contName}>
                    <img className={styles.foto} src={user.foto} alt="" />
                    <p className={styles.name}>{user.nombre}</p>
                  </div>
                </div>
                <div className={styles.contTags}>
                  <div className={styles.tags}>
                    <span className={styles.tag}>{user.rol || "Sin rol"}</span>
                  </div>
                  <div className={styles.tags}>
                    <span className={styles.tag}>{user.origen}</span>
                  </div>
                  <div className={styles.tags}>
                    <span className={styles.tag}>
                      {user.fondo || "Sin equipo"}
                    </span>
                  </div>
                  <div className={styles.tags}>
                    <span className={styles.tag}>{user.pie || "Sin dato"}</span>
                  </div>
                </div>
                <div className={styles.contHabilidades}>
                    <div className={styles.posiciones}>
                        <p className={styles.txt}>Posiciones:</p>
                        <p className={styles.txt}>{user.posicion || "Sin datos"}</p>
                        {user.posicionesSeleccionadas !== null && user.posicionesSeleccionadas.length > 0 && (
                            <p className={styles.txt}>{user.posicionesSeleccionadas.map(posicion => posicion).join(", ")}</p>
                        )}
                    </div>
                    <div className={styles.habilidades}>
                        <p className={styles.txt}>Habilidades:</p>
                        {user.habilidadesSeleccionadas !== null && user.habilidadesSeleccionadas.length > 0 && (
                            <p className={styles.txt}>{user.habilidadesSeleccionadas.map(habilidad => habilidad).join(", ")}</p>
                        ) || <p className={styles.txt}>Sin datos</p>}
                    </div>
                </div>
                <div className={styles.contExtra}>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Estado del registro: </p>
                    <p className={styles.extra}>{user.activo === true ? "Completado" : "Incompleto"}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Fecha de registro: </p>
                    <p className={styles.extra}>{formFecha(user.createdAt)}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Correo: </p>
                    <p className={styles.extra}>{user.correo}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Cumpleaños: </p>
                    <p className={styles.extra}>{user.dia || "Sin dato"}/{user.mes || "Sin dato"}/{user.anio || "Sin dato"} Edad: {calcularEdad(user.dia, user.mes, user.anio)}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Estado de notificaciones: </p>
                    <p className={styles.extra}>{user.notiMensajes === true ? "Habilitado" : "Deshabilitado"}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Ultima actualización del usuario: </p>
                    <p className={styles.extra}>{formFecha(user.updatedAt)}</p>
                  </div>
                  <div className={styles.extras}>
                    <p className={styles.extra}>Id: </p>
                    <p className={styles.extra}>{user.id}</p>
                  </div>
                </div>
                <div className={styles.contBtns}>
                  {user.activo === false ? (
                    <button
                      className={styles.btnActivar}
                      onClick={() => activar(user.id)}
                    >
                      Activar
                    </button>
                  ) : (
                    <button
                      className={styles.btnDesactivar}
                      onClick={() => desactivar(user.id)}
                    >
                      Desactivar
                    </button>
                  )}
                  <button
                    className={styles.btnDesactivar}
                    onClick={() => handleDeleteClick(user)}
                  >
                    Eliminar
                  </button>
                  <button
                    className={styles.btnVer}
                    onClick={() => inVisitor(user.id)}
                  >
                    Ver perfil
                  </button>
                  {/* <button
                      className={styles.btnDesactivar}
                    >
                      Reseteo de contraseña
                    </button> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usuarios;
