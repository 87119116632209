import React, { memo } from 'react';
import styles from './NavSecond.module.css';
import balonA from "../../assets/balonBtn.png"
import balonB from "../../assets/BalonBtnB.png"
import userA from "../../assets/UserBtnA.png"
import userB from "../../assets/UserBtn.png"

const NavSecond = memo(({ 
  bool, 
  boolTwo, 
  clickBool, 
  clickBoolTwo, 
  user, 
  clickBoolThree, 
  threeBool, 
  isVisitor 
}) => {
  const handleNavigation = (section) => {
    const actions = {
      metrics: () => {
        clickBool();
        window.scrollTo(0, 0);
        document.body.classList.remove('socialActive');
      },
      social: () => {
        clickBoolTwo();
        document.body.classList.add('socialActive');
        window.scrollTo(0, 0);
      },
      recruitment: () => {
        clickBoolThree();
        document.body.classList.remove('socialActive');
        window.scrollTo(0, 0);
      }
    };
    
    actions[section]?.();
  };

  const renderButton = (type, isActive, onClick, text) => (
    <div className={styles.btn} onClick={onClick}>
      <img 
        className={styles.icon} 
        src={type === 'metrics' ? (isActive ? balonB : balonA) : (isActive ? userB : userA)} 
        alt={text}
      />
      <p className={isActive ? styles.txtBtn : styles.txtBtnSelect}>{text}</p>
    </div>
  );

  return (
    <div className={styles.navSecond}>
      <div className={styles.botones}>
        {isVisitor ? (
          <>
            {renderButton('metrics', bool, () => handleNavigation('metrics'), 'PERFIL')}
            {renderButton('social', !bool, () => handleNavigation('social'), 'SOCIAL')}
          </>
        ) : (
          <>
            {renderButton('metrics', !bool, () => handleNavigation('metrics'), 'MÉTRICAS')}
            {user?.rol !== "Jugador" && 
              renderButton('social', !threeBool, () => handleNavigation('recruitment'), 'RECLUTAMIENTO')
            }
            {renderButton('social', !boolTwo, () => handleNavigation('social'), 'SOCIAL')}
          </>
        )}
      </div>
    </div>
  );
});

NavSecond.displayName = 'NavSecond';

export default NavSecond;