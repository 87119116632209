export const getTrainingAndMatchCount = (activities) => {
  // Si el array es null, undefined o está vacío, retorna 0 en todos los contadores
  console.log(activities)
  if (!activities || !Array.isArray(activities) || activities.length === 0) {
    return {
      entrenamientos: 0,
      partidos: 0,
      goles: 0,
      tiempo: 0,
      tiempoPartidos: 0  // Agregamos el contador de tiempo solo para partidos
    };
  }

  // Reduce el array para contar entrenamientos, partidos, goles y tiempo total
  return activities.reduce((acc, activity) => {
    if (activity.tipo === "Entrenamiento") {
      acc.entrenamientos += 1;
    } else if (activity.tipo === "Partido") {
      acc.partidos += 1;
      // Suma el tiempo de partido si existe
      if (activity.tiempo) {
        acc.tiempoPartidos += activity.tiempo;
      }
    }
    // Suma los goles si existen
    if (activity.goles) {
      acc.goles += activity.goles;
    }
    // Suma el tiempo total si existe
    if (activity.tiempo) {
      acc.tiempo += activity.tiempo;
    }
    return acc;
  }, { entrenamientos: 0, partidos: 0, goles: 0, tiempo: 0, tiempoPartidos: 0 });
};