export const getPreviousMetric = (metricsArray) => {
    if (!Array.isArray(metricsArray) || metricsArray.length < 2) {
      return { valor: null, tiempo: 'No hay registros previos' };
    }
  
    // Ordenamos el array por fecha de creación, más reciente primero
    const sortedMetrics = [...metricsArray].sort((a, b) => 
      new Date(b.createdAt) - new Date(a.createdAt)
    );
  
    // Obtenemos el penúltimo registro
    const previousMetric = sortedMetrics[1];
    
    // Calculamos la diferencia de tiempo
    const previousDate = new Date(previousMetric.createdAt);
    const currentDate = new Date();
    const diffTime = currentDate - previousDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    let tiempo;
    if (diffDays === 0) {
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      if (diffHours === 0) {
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        tiempo = `hace ${diffMinutes} minutos`;
      } else {
        tiempo = `hace ${diffHours} horas`;
      }
    } else if (diffDays === 1) {
      tiempo = 'hace un día';
    } else if (diffDays < 7) {
      tiempo = `hace ${diffDays} días`;
    } else if (diffDays < 30) {
      const weeks = Math.floor(diffDays / 7);
      tiempo = weeks === 1 ? 'hace una semana' : `hace ${weeks} semanas`;
    } else if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      tiempo = months === 1 ? 'hace un mes' : `hace ${months} meses`;
    } else {
      const years = Math.floor(diffDays / 365);
      tiempo = years === 1 ? 'hace un año' : `hace ${years} años`;
    }
  
    return {
      valor: previousMetric.valor,
      tiempo
    };
  };