import { useEffect, useState, useCallback } from "react";
import styles from "./Visitor.module.css";
import { getVisitorPublicaciones, getVisitorUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import StadisticsVisitor from "../Stadistics/StadisticsVisitor.jsx";
import { useParams } from "react-router";
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import UserVisitor from "../User/UserVisitor.jsx";
import FeedVisitor from "../Feed/FeedVisitor.jsx";
import Otro from "../Otro/Otro.jsx";
import ModalUser from "../ModalUser/ModalUser.jsx";
import ModalMetricaVisitor from "../ModalMetrica/ModalMetricaVisitor.jsx";
import axios from "axios";
import { URL } from "../../link";
import ModalBSD from "../ModalBSD/ModalBSD.jsx";
import NavSecond from "../NavSecond/NavSecond.jsx";

const Visitor = ({
  inVisitor,
  notificacionesBool,
  clickMessage,
  desactivarNotificacion,
  userBool,
  logOut,
  inEdit,
  noticias,
  publicacionView,
  seguir,
}) => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const detailUser = useSelector((state) => state.dataVisitorUser);
  const publicaciones = useSelector((state) => state.visitorPublicaciones);
  const [entrenamientos, setEntrenamientos] = useState([]);
  const userPubliaciones = [];
  const [metricaBool, setMetricaBool] = useState(false);
  const [metricaValor, setMetricaValor] = useState("");

  const [isVisitorBool, setIsVisitorBool] = useState(true);
  const [navSecondActive, setNavSecondActive] = useState(true);
  const [scores, setScores] = useState([]);
  const [metricas, setMetricas] = useState([])
  const [modalScore, setModalScore] = useState(false);

  const [bool, setBool] = useState(false);
  const [threeBool, setTrheeBool] = useState(false);
  const [boolTwo, setBoolTwo] = useState(false);

  const metricaClick = (metricaV) => {
    setMetricaBool(true);
    setMetricaValor(metricaV);
    setNavSecondActive(false);
    window.scrollTo(0, 0)
  };

  const getScoresByVisitor = async (data) => {
    if (data?.userId) {
      try {
        const response = await axios.post(`${URL}/score/dates`, data);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  };

  const getScores = async () => {
    try {
      if(detailUser.rol === "Jugador") {
        const response = await axios.get(`${URL}/score/${detailUser.id}`);
        setScores(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEntrenamientosByVisitor = async (data) => {
    if (data?.userId) {
      try {
        const response = await axios.post(`${URL}/entrenamiento/dates`, data);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
  };

  const getMetricas = async () => {
    try {
        const response = await axios.get(`${URL}/metrica/user/${detailUser.id}`)
        setMetricas(response.data.Metricas)
    } catch (error) {
        console.log(error)
    }
  }

  const getEntrenamientos = async () => {
    try {
      const response = await axios.get(`${URL}/entrenamiento/user/${id}`);
      setEntrenamientos(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModalBSD = (bool) => {
    if (bool) {
      setModalScore(true);
      setNavSecondActive(false);
    } else {
      setModalScore(false);
      setNavSecondActive(true);
    }
    window.scrollTo(0, 0)
  };

  const handleMetricaClick = (bool) => {
    setMetricaBool(bool);
    setNavSecondActive(true);
    window.scrollTo(0, 0);
  };

  const clickBool = useCallback(() => {
    setBool(false);
    setTrheeBool(false);
    setBoolTwo(false);
  }, []);

  const clickBoolTwo = useCallback(() => {
    setTrheeBool(false);
    setBool(true);
    setBoolTwo(true);
  }, []);

  const clickBoolThree = useCallback(() => {
    setBool(false);
    setTrheeBool(true);
    setBoolTwo(false);
  }, []);

  useEffect(() => {
    dispatch(getVisitorUser(id));
    dispatch(getVisitorPublicaciones(id));
    getEntrenamientos();
    getScores();
    getMetricas();
  }, [dispatch, id]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        (notificacionesBool &&
          event.target.closest(".modal-notificaciones") === null) ||
        (userBool && event.target.closest(".modal-user") === null)
      ) {
        desactivarNotificacion();
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [notificacionesBool, desactivarNotificacion, userBool]);

  return (
    <div className={styles.container}>
      <div className={bool ? styles.partDesactive : styles.partOne}>
        {detailUser.rol === "Jugador" ? (
          <StadisticsVisitor
            detailUser={detailUser}
            metricaClick={metricaClick}
            entrenamientos={entrenamientos}
          />
        ) : (
          <Otro user={detailUser} isVisitorBool={isVisitorBool} />
        )}
      </div>

      <div className={bool ? styles.partDesactive : styles.partTwo}>
        {detailUser.rol === "Jugador" && (
          <UserVisitor
            user={detailUser}
            handleOpenModalBSD={handleOpenModalBSD}
            scores={scores}
            setNavSecondActive={setNavSecondActive}
            navSecondActive={navSecondActive}
          />
        )}
      </div>

      <div className={boolTwo ? styles.partThre : styles.partDesactive}>
        <FeedVisitor
          user={detailUser}
          publicaciones={publicaciones}
          userPubliaciones={userPubliaciones}
          inVisitor={inVisitor}
          noticias={noticias}
          publicacionView={publicacionView}
          seguir={seguir}
        />
      </div>

      {metricaBool && (
        <ModalMetricaVisitor
          setMetricaBool={handleMetricaClick}
          metricaV={metricaValor}
          user={detailUser}
        />
      )}

      {notificacionesBool && (
        <ModalNotificaciones 
          clickMessage={clickMessage} />
      )}

      {userBool === true && 
        <ModalUser 
          logOut={logOut} 
          inEdit={inEdit} />
      }

      {navSecondActive && (
        <div className={styles.navSecond}>
          <NavSecond
            bool={bool}
            boolTwo={boolTwo}
            clickBool={clickBool}
            clickBoolTwo={clickBoolTwo}
            clickBoolThree={clickBoolThree}
            user={detailUser}
            threeBool={threeBool}
            isVisitor={true}
          />
        </div>
      )}

      {modalScore && (
        <ModalBSD 
          user={detailUser}
          isVisitor={true}
          entrenamientos={entrenamientos}
          setModalScoreBSD={handleOpenModalBSD}
          getScoresByVisitor={getScoresByVisitor}
          getEntrenamientosByVisitor={getEntrenamientosByVisitor}
          metricas={metricas}
        />
      )}

    </div>
  );
};

export default Visitor;
