import fuerza from "../../../assets/fuerza.png";
import velocidad from "../../../assets/velocidad.png";
import resistencia from "../../../assets/resistencia.png";
import salto from "../../../assets/salto.png";

export const generarDataMetricas = (metricas, labels) => { 
  // Verificar si hay métricas
  if (!metricas || metricas.length === 0) return null;

  console.log(metricas)

  // Mapeo de nombres de métricas a colores
  const metricaConfig = {
    fuerza: {
      color: 0
    },
    velocidad: {
      color: 1
    },
    resistencia: {
      color: 2
    },
    salto: {
      color: 3
    }
  };

  // Agrupar métricas por nombre y fecha
  const metricasPorFecha = {};
  metricas.forEach(metrica => {
    const fecha = new Date(metrica.createdAt);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const año = fecha.getFullYear().toString().slice(-2);
    const fechaKey = `${dia}/${mes}/${año}`;
    
    if (!metricasPorFecha[fechaKey]) {
      metricasPorFecha[fechaKey] = {};
    }
    if (!metricasPorFecha[fechaKey][metrica.nombre] || 
        metricasPorFecha[fechaKey][metrica.nombre] < metrica.valor) {
      metricasPorFecha[fechaKey][metrica.nombre] = metrica.valor;
    }
  });

  console.log("Métricas por fecha:", metricasPorFecha);
  console.log("Labels recibidos:", labels);

  // Generar datasets separados para cada tipo de métrica
  const datasets = Object.keys(metricaConfig).map(nombreMetrica => {
    const data = [];
    const metricasInfo = labels.map(() => ({
      valor: 0,
      nombre: nombreMetrica
    }));

    labels.forEach((label, index) => {
      const valor = metricasPorFecha[label]?.[nombreMetrica] || 0;
      data.push({
        x: label,
        y: valor > 0 ? valor : null,
        r: valor > 0 ? Math.max(6, valor/10) : 0
      });
      
      if (valor > 0) {
        metricasInfo[index] = {
          valor: valor,
          nombre: nombreMetrica
        };
      }
    });

    return {
      type: 'bubble',
      label: nombreMetrica.charAt(0).toUpperCase() + nombreMetrica.slice(1),
      data: data,
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ][metricaConfig[nombreMetrica].color],
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
      ][metricaConfig[nombreMetrica].color],
      metricasInfo: metricasInfo,
      tooltip: {
        callbacks: {
          label: function(context) {
            const info = context.dataset.metricasInfo[context.dataIndex];
            if (info && info.valor > 0) {
              return `${info.nombre}: ${info.valor}`;
            }
            return null;
          }
        }
      }
    };
  }).filter(dataset => dataset.data.some(point => point.y !== null));

  console.log("Datasets generados:", datasets);

  return datasets;
};
