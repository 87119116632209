import { useEffect, useRef, useState } from 'react'

const VideoPlayer = ({ video, width, height }) => {
    const [error, setError] = useState(false)
    const playerInstanceRef = useRef(null)
    const containerRef = useRef(null)

    useEffect(() => {
        let mounted = true;

        const initPlayer = async () => {
            if (!video || !containerRef.current || !window.cloudinary) return;

            try {
                // Limpiar instancia previa si existe
                if (playerInstanceRef.current) {
                    playerInstanceRef.current.dispose();
                }

                // Crear el elemento video
                const videoElement = document.createElement('video');
                videoElement.className = 'video-js vjs-big-play-centered';
                containerRef.current.innerHTML = '';
                containerRef.current.appendChild(videoElement);

                // Crear nueva instancia
                const player = window.cloudinary.videoPlayer(videoElement, {
                    cloud_name: "dvwtgluwc",
                    publicId: video,
                    secure: true,
                    sourceTypes: ['mp4'],
                    controls: true,
                    fluid: true,
                    preload: 'auto',
                    autoplay: false,
                    muted: false,
                    bigPlayButton: true,
                    loadingSpinner: true,
                    transformation: {
                        width: 720,
                        height: 480,
                        crop: 'limit',
                        quality: 'auto'
                    },
                    poster: {
                        transformation: {
                            width: 720,
                            height: 480,
                            crop: 'limit',
                            quality: 'auto'
                        }
                    }
                });

                if (mounted) {
                    playerInstanceRef.current = player;

                    player.on('error', () => {
                        if (mounted) {
                            console.error('Error en la reproducción del video');
                            setError(true);
                        }
                    });
                }
            } catch (err) {
                console.error('Error al inicializar el reproductor:', err);
                if (mounted) {
                    setError(true);
                }
            }
        };

        // Pequeño retraso para asegurar que Cloudinary esté cargado
        setTimeout(initPlayer, 100);

        return () => {
            mounted = false;
            if (playerInstanceRef.current) {
                try {
                    playerInstanceRef.current.dispose();
                    playerInstanceRef.current = null;
                } catch (err) {
                    console.error('Error al limpiar el reproductor:', err);
                }
            }
        };
    }, [video]);

    if (error) {
        return (
            <div style={{
                backgroundColor: '#1C1D1E',
                color: '#E4FD3F',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                margin: '10px 0'
            }}>
                Error al cargar el video. Por favor, intenta más tarde.
            </div>
        );
    }

    return (
        <div 
            ref={containerRef}
            style={{
                width: width || '100%',
                maxWidth: '100%',
                backgroundColor: '#000',
                borderRadius: '8px',
                overflow: 'hidden'
            }}
        />
    );
};

export default VideoPlayer;
