import React, { useEffect, useState } from 'react';
import styles from "./ModalLista.module.css";
import alto from "../../assets/alto.png";
import medio from "../../assets/medio.png";
import bajo from "../../assets/bajo.png";
import { Bubble } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import user from "../../assets/Logo.svg";
import otro from "../../assets/user.png";
import lineOne from "../../assets/LineOne.png"
import lineTwo from "../../assets/linetwo.png"
import cancha from "../../assets/CampoLimpio.png"
import ModalBuscar from '../ModalBuscar/ModalBuscar';
import { useDispatch } from 'react-redux';
import { getListas } from '../../actions';
import { URL } from '../../link';
import axios from 'axios';
import ojo from "../../assets/ojo.png"
import { calcularEdad, formatoLegible, promedioScore } from '../funciones';

const ModalLista = ({ setModalListaBool, modalListaBool, listaEditar, inVisitor }) => {

    const [listatReckup, setListaReckup] = useState(listaEditar)
    const dataUserLocal = JSON.parse(localStorage.getItem("dataUser"))
    const [allJugadores, setAllJugadores] = useState([])

    const [jugadoresBool, setJugadoresBool] = useState(true)

    const listaJugadores = listatReckup.Usuarios.map(jugador => {
        return {
            x: jugador.scores[0]?.valor ?? 0,
            y: jugador.expTime,
            image: jugador.foto
        }
    })

    const getJugadores = async () => {
        try {
            const response = await axios.get(`${URL}/usuario/jugadoresByReclutador`)
            setAllJugadores(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const dispach = useDispatch()

    const addJugador = async (jugadorID) => {
        const data = {
            id: listatReckup.id,
            jugadorId: jugadorID
        }
        try {
            const response = await axios.put(`${URL}/lista/agregar`, data)
            setListaReckup(response.data)
            dispach(getListas(dataUserLocal.id))
        } catch (error) {
            console.log(error);
        }
    }

    const deleteJugador = async (jugadorID) => {
        const data = {
            id: listatReckup.id,
            jugadorId: jugadorID
        }
        try {
            const response = await axios.put(`${URL}/lista/eliminar`, data)
            setListaReckup(response.data)
            dispach(getListas(dataUserLocal.id))
        } catch (error) {
            console.log(error);
        }
    }

    const [modalBuscarBool, setModalBuscarBool] = useState(false)

    const [xMax, setxMax] = useState(100)
    const [yMax, setyMax] = useState(30)

    const [canchaBool, setCanchaBool] = useState(false)

    const getXMax = () => {
        if (listatReckup.Usuarios.length > 0) {
            setxMax(Math.max(...listatReckup.Usuarios.map(jugador => jugador.scores[0]?.valor ?? 0)))
        }
    }

    const getYMax = () => {
        if (listatReckup.Usuarios.length > 0) {
            setyMax(Math.max(...listatReckup.Usuarios.map(jugador => jugador.expTime)))
        }
    }

    useEffect(() => {
        getXMax()
        getYMax()
    }, [])

    useEffect(() => {
        getJugadores()
    }, [])


    useEffect(() => {
        const images = listaJugadores.map(player => {
            const image = new Image();
            image.src = player.image;
            return image;
        });
    }, []);

    const setColor = (xValue, yValue) => {
        const intensity = Math.sqrt(xValue ** 2 + yValue ** 2) / Math.sqrt(xMax ** 2 + yMax ** 2);

        const colorValue = Math.round(intensity * 255);
        let red = 255 - colorValue;
        let green = colorValue;

        if (xValue > xMax / 2 && yValue > yMax / 2) {
            red = 255;
            green = 255;
        }
        // Crear un color RGB utilizando la intensidad para cada componente
        const color = `rgb(${red}, ${green}, 0)`;
        return color;
    }



    const customPlugin = {
        id: 'customPlugin',
        beforeDraw: (chart) => {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, datasetIndex) => {
                dataset.data.forEach((playerData, index) => {
                    const image = new Image();
                    const color = setColor(playerData.x, playerData.y)
                    image.src = playerData.image; //Posiblementee si sea image
                    const bubble = chart.getDatasetMeta(datasetIndex).data[index];
                    const { x, y } = bubble.getProps(['x', 'y'], true);
                    const radius = 15; // Tamaño exacto de las burbujas
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(x, y, radius, 0, 2 * Math.PI);
                    ctx.clip();
                    ctx.drawImage(image, x - radius, y - radius, radius * 2, radius * 2);
                    ctx.beginPath();
                    ctx.arc(x, y, radius, 0, 2 * Math.PI);
                    ctx.strokeStyle = color; // Borde rojo
                    ctx.lineWidth = 3;
                    ctx.stroke();
                    ctx.restore();
                });
            });
        }
    };


    // useEffect(() => {
    //     Chart.register(customPlugin);
    // }, []);

    Chart.register(customPlugin);

    const data = {
        datasets: [{
            label: 'Jugadores',
            data: listaJugadores,
            backgroundColor: 'rgba(0, 0, 0, 0)', // Fondo transparente ya que se dibujarán imágenes
            borderColor: 'rgba(255, 99, 132, 0)',
            borderWidth: 1,
        }],
    };

    const options = {
        aspectRatio: 3 / 2,
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    color: "rgba(30, 33, 37, 1)"
                },
                ticks: {
                    color: "rgba(255, 255, 255, 1)"
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: "rgba(0, 0, 0, 0)"
                },
                ticks: {
                    color: "rgba(255, 255, 255, 1)",
                    callback: function (value, index, values) {
                        return value + " " + "años"
                    }
                }
            },
        },
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0,
            },
        },
        animation: {
            duration: 0,
            easing: 'linear',
        },
    };

    const getPosicionBottom = (posicionJugador) => {
        if (posicionJugador === "Portero") {
            return "50px"
        }
        if (posicionJugador === "DefensaCentral" || posicionJugador === "DefensaLateralIzquierdo" || posicionJugador === "DefensaLateralDerecho") {
            return "95px"
        }
        if (posicionJugador === "Mediocentro" || posicionJugador === "InteriorIzquierdo" || posicionJugador === "InteriorDerecho") {
            return "175px"
        }
        if (posicionJugador === "DelanteroCentro" || posicionJugador === "ExtremoIzquierdo" || posicionJugador === "ExtremoDerecho")
            return "245px"
        if (posicionJugador === "MediocentroDefensivo")
            return "135px"
        if (posicionJugador === "MediocentroOfensivo")
            return "210px"
    }

    const getPosicionRight = (posicionJugador) => {
        if (posicionJugador === "Portero" || posicionJugador === "DefensaCentral" || posicionJugador === "Mediocentro" || posicionJugador === "DelanteroCentro" || posicionJugador === "MediocentroDefensivo" || posicionJugador === "MediocentroOfensivo")
            return "195px"
        if (posicionJugador === "DefensaLateralIzquierdo")
            return "305px"
        if (posicionJugador === "DefensaLateralDerecho")
            return "85px"
        if (posicionJugador === "InteriorIzquierdo")
            return "85px"
        if (posicionJugador === "InteriorDerecho")
            return "305px"
        if (posicionJugador === "ExtremoIzquierdo")
            return "135px"
        if (posicionJugador === "ExtremoDerecho")
            return "260px"
    }

    const porteros = listatReckup.Usuarios.filter((jugador => jugador.posicion === "Portero"))
    const DefensaCentrales = listatReckup.Usuarios.filter((jugador => jugador.posicion === "DefensaCentral"))
    const DefensaLateralIzquierdos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "DefensaLateralIzquierdo"))
    const DefensaLateralDerechos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "DefensaLateralDerecho"))
    const Mediocentros = listatReckup.Usuarios.filter((jugador => jugador.posicion === "Mediocentro"))
    const InteriorIzquierdos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "InteriorIzquierdo"))
    const InteriorDerechos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "InteriorDerecho"))
    const DelanterosCentrales = listatReckup.Usuarios.filter((jugador => jugador.posicion === "DelanteroCentro"))
    const ExtremosIzquierdos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "ExtremoIzquierdo"))
    const ExtremosDerechos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "ExtremoDerecho"))
    const MediocentrosDefensivos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "MediocentroDefensivo"))
    const MediocentrosOfensivos = listatReckup.Usuarios.filter((jugador => jugador.posicion === "MediocentroOfensivo"))


    return (
        <div className={styles.conteiner}>
            <div className={styles.modalLista}>
            {modalBuscarBool === false && <p className={styles.btnTtxt} onClick={() => setModalListaBool(false)}>{"< Regresar"}</p>}
                {!modalBuscarBool ? <div className={styles.cont}>
                <p className={styles.back} onClick={() => setModalListaBool(!modalListaBool)}>{`< Listas`}</p>
                    {canchaBool ? <div className={styles.contCancha}>
                        <div className={styles.contTitle}>
                            <h3 className={styles.title}>{listatReckup.nombre.toUpperCase()}</h3>
                            <div className={styles.contBSD}>
                                <p className={styles.bsdTxt}>BSD PROMEDIO</p>
                                <p className={styles.bsd}>{promedioScore(listatReckup.Usuarios)}</p>
                            </div>
                        </div>
                        <div className={styles.contDescripcion}>
                            <h4 className={styles.descripcion}>{listatReckup.descripcion}</h4>
                        </div>
                        <div className={styles.botonera}>
                            <div className={jugadoresBool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setJugadoresBool(true)}>
                                <p className={styles.txtMenu}>Jugadores</p>
                            </div>
                            <div className={!jugadoresBool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setJugadoresBool(false)}>
                                <p className={styles.txtMenu}>Gráficas</p>
                            </div>
                        </div>
                        <div className={jugadoresBool ? styles.contSubTitleDes : styles.contSubTitle}>
                            <h4 className={styles.subTitle}>COMPARATIVO</h4>
                            <p className={styles.btnTxt} onClick={() => setCanchaBool(!canchaBool)}>VER GRÁFICA</p>
                        </div>
                        
                        <div className={jugadoresBool ? styles.contDesepenoDes : styles.contDesepeno}>
                            <div className={styles.desempeno}>
                                <img className={styles.icon} src={bajo} alt="" />
                                <p className={styles.desName}>Bajo desempeño</p>
                            </div>
                            <div className={styles.desempeno}>
                                <img className={styles.icon} src={medio} alt="" />
                                <p className={styles.desName}>Medio desempeño</p>
                            </div>
                            <div className={styles.desempeno}>
                                <img className={styles.iconA} src={alto} alt="" />
                                <p className={styles.desName}>Alto desempeño</p>
                            </div>
                        </div>
                        <div className={jugadoresBool ? styles.canchaDes : styles.cancha}>
                            <img className={styles.canchaImg} src={cancha} alt="" />
                            <div className={styles.areaPorteros}>
                                {porteros.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDefensaCentrales}>
                                {DefensaCentrales.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDefensaLateralIzquierdos}>
                                {DefensaLateralIzquierdos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDefensaLateralDerechos}>
                                {DefensaLateralDerechos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaMediocentros}>
                                {Mediocentros.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaInteriorIzquierdos}>
                                {InteriorIzquierdos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaInteriorDerechos}>
                                {InteriorDerechos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDelanteroCentrales}>
                                {DelanterosCentrales.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDelanteroLateralIzquierdos}>
                                {ExtremosIzquierdos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaDelanteroLateralDerechos}>
                                {ExtremosDerechos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaMedioCentroDefensivo}>
                                {MediocentrosDefensivos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                            <div className={styles.areaMedioCentroOfensivo}>
                                {MediocentrosOfensivos.map((jugador, index) => (
                                    <img key={index} className={styles.posicionCancha} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0), marginLeft: `${index * 12}px` }} />
                                ))}
                            </div>
                        </div>
                        <button className={styles.btnAgregar} onClick={() => setModalBuscarBool(!modalBuscarBool)}>Agregar jugador</button>
                    </div> :
                        <div className={styles.contGraficos}>
                            <div className={styles.contTitle}>
                                <h3 className={styles.title}>{listatReckup.nombre.toUpperCase()}</h3>
                                <div className={styles.contBSD}>
                                    <p className={styles.bsdTxt}>BSD PROMEDIO</p>
                                    <p className={styles.bsd}>{promedioScore(listatReckup.Usuarios)}</p>
                                </div>
                            </div>
                            <div className={styles.contDescripcion}>
                                <h4 className={styles.descripcion}>{listatReckup.descripcion}</h4>
                            </div>
                            <div className={styles.botonera}>
                                <div className={jugadoresBool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setJugadoresBool(true)}>
                                    <p className={styles.txtMenu}>Jugadores</p>
                                </div>
                                <div className={!jugadoresBool ? styles.btnMenuSelect : styles.btnMenu} onClick={() => setJugadoresBool(false)}>
                                    <p className={styles.txtMenu}>Gráficas</p>
                                </div>
                            </div>
                            <div className={jugadoresBool ? styles.contSubTitleDes : styles.contSubTitle}>
                                <h4 className={styles.subTitle}>COMPARATIVO</h4>
                                <p className={styles.btnTxt} onClick={() => setCanchaBool(!canchaBool)}>VER CANCHA</p>
                            </div>
                            <div className={jugadoresBool ? styles.contDesepenoDes : styles.contDesepeno}>
                                <div className={styles.desempeno}>
                                    <img className={styles.icon} src={bajo} alt="" />
                                    <p className={styles.desName}>Bajo desempeño</p>
                                </div>
                                <div className={styles.desempeno}>
                                    <img className={styles.icon} src={medio} alt="" />
                                    <p className={styles.desName}>Medio desempeño</p>
                                </div>
                                <div className={styles.desempeno}>
                                    <img className={styles.iconA} src={alto} alt="" />
                                    <p className={styles.desName}>Alto desempeño</p>
                                </div>
                            </div>
                            <div className={jugadoresBool ? styles.contGraficaDes : styles.contGrafica}>
                                <Bubble data={data} options={options} />
                                <img className={styles.lineOne} src={lineOne} alt="" />
                                <img className={styles.lineTwo} src={lineTwo} alt="" />
                            </div>
                             <button className={styles.btnAgregar} onClick={() => setModalBuscarBool(!modalBuscarBool)}>Agregar jugador</button>
                        </div>}
                    <div className={jugadoresBool ? styles.contJugadores : styles.constDesactive}>
                        <p className={styles.cerrar} onClick={() => setModalListaBool(!modalListaBool)}>X</p>
                        <div className={styles.contTitleB}>
                            <h3 className={styles.subTitle}>JUGADORES</h3>
                            <button className={styles.btn} onClick={() => setModalBuscarBool(!modalBuscarBool)}>Agregar jugador</button>
                        </div>
                        <div className={styles.contListaJugadores}>
                            {listatReckup.Usuarios.map((jugador, index) => (
                                <div className={index % 2 === 1 ? styles.contJugador : styles.contJugadorTwo} key={index}>
                                    <h3 className={styles.num}>{index + 1}</h3>
                                    <img className={styles.foto} src={jugador.foto} alt="" style={{ borderColor: setColor(jugador.expTime, jugador.scores[0]?.valor ?? 0) }} />
                                    <div className={styles.info}>
                                        <h3 className={styles.name}>{jugador.nombre}</h3>
                                        <p className={styles.posicion}>{formatoLegible(jugador.posicion)} - {calcularEdad(jugador.dia, jugador.mes, jugador.anio)} años</p>
                                    </div>
                                    <div className={styles.bsdJugador}>
                                        <p className={styles.bsdTxtJugador}>BSD</p>
                                        <p className={styles.bsdByJugador}>{jugador.scores[0]?.valor ?? 0}</p>
                                    </div>
                                    <div className={styles.btnAdd} onClick={() => deleteJugador(jugador.id)}>-</div>
                                    <div className={styles.btnAdd} onClick={() => inVisitor(jugador.id)}>
                                        <img className={styles.iconB} src={ojo} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button className={styles.btnAgregar} onClick={() => setModalBuscarBool(!modalBuscarBool)}>Agregar jugador</button>
                    </div>
                </div> : <ModalBuscar allJugadores={allJugadores} setModalBuscarBool={setModalBuscarBool} modalBuscarBool={modalBuscarBool} addJugador={addJugador} listatReckup={listatReckup} deleteJugador={deleteJugador} inVisitor={inVisitor} />}
            </div>
        </div>
    );
};

export default ModalLista;

// bottom: getPosicionBottom(jugador.posicion), right: getPosicionRight(jugador.posicion),
