import styles from "./User.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editarPerfil } from "../../actions";
import { formatoLegible } from "../funciones.js";
import Posicion from "./components/Posicion";
import Habilidades from "./components/Habilidades";
import Metricas from "./components/Metricas.jsx";
import subir from "../../assets/subir.png";
import bajar from "../../assets/bajar.png";
import { getPreviousMetric } from "./utils/getPreviousMetric";


const User = ({
  handleOpenModalBSD,
  scores,
  entrenamientos
}) => {
  const user = JSON.parse(localStorage.getItem("dataUser"));

  const [principal, setPrincipal] = useState("");
  const [secundaria, setSecundaria] = useState("");
  const [terciaria, setTerciaria] = useState("");
  const [editPosicion, setEditPosicion] = useState(false);

  const [editHabilidades, setEditHabilidades] = useState(false);
  const [editMetricas, setEditMetricas] = useState(false);
  const [habilidadesSeleccionadas, setHabilidadesSeleccionadas] = useState(
    user.habilidadesSeleccionadas
  );
  const [valores, setValores] = useState({
    paseCorto: user.paseCorto,
    paseLargo: user.paseLargo,
    cabeceo: user.cabeceo,
    regate: user.regate,
    defensa: user.defensa,
    tiros: user.tiros,
  });

  const previousMetric = getPreviousMetric(scores);

  const dispatch = useDispatch();

  const editP = () => {
    setEditPosicion(!editPosicion);
  };

  const editH = () => {
    setEditHabilidades(!editHabilidades);
  };

  const editM = () => {
    setEditMetricas(!editMetricas);
  };

  const Guardar = async () => {
    user.posicion = principal || user.posicion;
    user.posicionesSeleccionadas = [
      secundaria || user.posicionesSeleccionadas[0],
      terciaria || user.posicionesSeleccionadas[1],
    ];
    user.habilidadesSeleccionadas =
      habilidadesSeleccionadas || user.habilidadesSeleccionadas;
    user.paseCorto = valores.paseCorto || user.paseCorto;
    user.paseLargo = valores.paseLargo || user.paseLargo;
    user.cabeceo = valores.cabeceo || user.cabeceo;
    user.regate = valores.regate || user.regate;
    user.defensa = valores.defensa || user.defensa;
    user.tiros = valores.tiros || user.tiros;

    const response = await dispatch(editarPerfil(user.id, user));
    localStorage.setItem("dataUser", JSON.stringify(response));
    setEditPosicion(false);
    setEditHabilidades(false);
    setEditMetricas(false);
  };

  const onChanceInputs = (habilidad) => {
    const index = habilidadesSeleccionadas.indexOf(habilidad);
    if (index === -1) {
      setHabilidadesSeleccionadas([...habilidadesSeleccionadas, habilidad]);
    } else {
      const nuevasHabilidades = [...habilidadesSeleccionadas];
      nuevasHabilidades.splice(index, 1);
      setHabilidadesSeleccionadas(nuevasHabilidades);
    }
  };

  const handleChange = (campo, valor) => {
    setValores((prevState) => ({ ...prevState, [campo]: valor }));
  };

  const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

  return (
    <div className={styles.conteiner}>
      <div className={styles.contDesempeno}>
        <p className={styles.seccion}>DESEMPEÑO GENERAL</p>
        <div className={styles.desempeno}>
          <div className={styles.contData}>
            <div className={styles.contBSD}>
              <div className={styles.bsd}>
                <p className={styles.bsdText}>BSD SCORE</p>
                <p className={styles.bsdValor}>{user?.scores?.[0]?.valor ?? 0}</p>
              </div>
            </div>
          </div>
          <div className={styles.anterior}>
            <p className={styles.anteriorText}>{previousMetric.tiempo}</p>
            <div className={styles.contValor}>
              <img className={styles.icono} src={previousMetric.valor > user?.scores?.[0]?.valor ? bajar : subir} alt="subir" />
              <p className={styles.valor}>{previousMetric.valor}</p>
            </div>
          </div>
          <button className={styles.btnResumen} onClick={() => handleOpenModalBSD(true)}>VER RESUMEN</button>
        </div>
      </div>
      <Posicion
        user={user}
        editPosicion={editPosicion}
        editP={editP}
        Guardar={Guardar}
        posicionesLegibles={posicionesLegibles}
        formatoLegible={formatoLegible}
        setPrincipal={setPrincipal}
        setSecundaria={setSecundaria}
        setTerciaria={setTerciaria}
      />
      <Habilidades
        user={user}
        editHabilidades={editHabilidades}
        editH={editH}
        Guardar={Guardar}
        habilidadesSeleccionadas={habilidadesSeleccionadas}
        onChanceInputs={onChanceInputs}
        handleChange={handleChange}
      />
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
        <p className={styles.btnTxt} onClick={editM}>
          EDITAR
        </p>
      </div>
      <div className={styles.tab}>
        <div className={styles.tabItemActive}>
          <p className={styles.tabText}>HABILIDADES</p>
        </div>
      </div>
      <Metricas
        user={user}
        editMetricas={editMetricas}
        editM={editM}
        Guardar={Guardar}
        valores={valores}
        handleChange={handleChange}
      />
    </div>
  );
};

export default User;


