import React, { useState, useCallback, useEffect } from 'react';
import style from "./ModalImage.module.css";
import Cropper from 'react-easy-crop';
import uploadImage from "../../assets/SubirImagen.png";
import btnx from "../../assets/back.svg"; // Imagen del botón de regreso

const ModalImage = ({ setIsModalOpen, user, setUrlNewFoto }) => {
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [error, setError] = useState(null);

    const getCroppedImg = (imageSrc, pixelCrop) => {
        const image = new Image();
        image.src = imageSrc;

        return new Promise((resolve, reject) => {
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = pixelCrop.width;
                canvas.height = pixelCrop.height;

                ctx.drawImage(
                    image,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    0,
                    0,
                    pixelCrop.width,
                    pixelCrop.height
                );

                canvas.toBlob((blob) => {
                    const url = URL.createObjectURL(blob);
                    resolve(blob);
                }, 'image/jpeg');
            };

            image.onerror = () => reject('Error al procesar la imagen.');
        });
    };

    const processFile = (file) => {
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl);
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        processFile(file);
    };

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCrop = useCallback(async () => {
        if (croppedAreaPixels && image) {
            try {
                const croppedImageBlob = await getCroppedImg(image, croppedAreaPixels);
                const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
                setCroppedImage(croppedImageUrl);
            } catch {
                setError('Error al recortar la imagen.');
            }
        }
    }, [image, croppedAreaPixels]);

    const cloud_name = "dvwtgluwc";

    const handleUploadToCloudinary = async () => {
        if (!croppedImage) return;

        const formData = new FormData();
        const response = await fetch(croppedImage);
        const blob = await response.blob();
    
        formData.append('file', blob); 
        formData.append('upload_preset', 'bqgn8ppn');
        formData.append('cloud_name', cloud_name);
    
        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
                method: 'POST',
                body: formData,
            });
    
            const data = await response.json();
    
            if (data.secure_url) {
                setUrlNewFoto(data.secure_url);
                
                setTimeout(() => {
                    setIsModalOpen(false);
                }, 500);
            }
        } catch (error) {
            console.error('Error al subir la imagen:', error);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        processFile(file);
    };

    const resetImage = () => {
        setImage(null);
        setCroppedAreaPixels(null);
        setCroppedImage(null);
        setError(null);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    useEffect(() => {
        return () => {
            if (image) {
                URL.revokeObjectURL(image);
            }
            if (croppedImage) {
                URL.revokeObjectURL(croppedImage);
            }
        };
    }, [image, croppedImage]);

    return (
        <div className={style.modalImage}>
            <img 
                    src={btnx} 
                    alt="Regresar" 
                    className={style.backButton} 
                    onClick={() => setIsModalOpen(false)}
                />
            <div className={style.conteiner}>
                {error && <div className={style.error}>{error}</div>}
                {!image ? (
                    <div className={style.dragDropArea} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}>
                        <label className={style.inputext}>
                            <img src={uploadImage} alt="subir imagen" className={style.btnUpload} />
                            <input
                                type="file"
                                onChange={handleImageUpload}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                        </label>
                    </div>
                ) : (
                    <div className={style.contEdit}>
                        <div className={style.contEditImage}>
                            <div className={style.contCanvas}>
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            <div className={style.buttonGroup}>
                                <button
                                    className={`${style.btnCrop} ${style.smallButton}`}
                                    onClick={resetImage}
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleCrop}
                                    disabled={!image || !croppedAreaPixels}
                                    className={style.btnCrop}
                                >
                                    Recortar
                                </button>
                            </div>
                        </div>
                        <div className={style.contPreview}>
                            <p className={style.nombre}>{user.nombre}</p>
                            {croppedImage ? <img src={croppedImage} alt="Preview" className={style.previewImg} /> : <img src={user.foto} alt="Preview" className={style.previewImg} />}
                            {croppedImage && (
                                <button onClick={handleUploadToCloudinary} className={style.btnSave2}>
                                    Subir Imagen
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalImage;
