import { useEffect } from 'react';
import style from "./Nosotros.module.css"
import imgTwo from "../../../assets/imgNTwo.png"
import imgTree from "../../../assets/imgNTree.png"
import balon from "../../../assets/balon.png"
import comillas from "../../../assets/comillas.png"
import imgFour from "../../../assets/imgFour.png"
import imgAppFive from "../../../assets/imgAppFive.png"
import logo from "../../../assets/Logo.svg"
import insta from "../../../assets/Insta.png"
import twitter from "../../../assets/twe.png"
import face from "../../../assets/face.png"

const Nosotros = ({ handleRegister, handleSesion, handleNosotros, handleContacto, handleInicio }) => {
    // useEffect(() => {
    //     handleNosotros();
    // }, [handleNosotros]);
    return (
        <div className={style.inicio}>
            <div className={style.contHero}>
                <div className={style.hero}>
                    <div className={style.contTxtHero}>
                        <h1 className={style.txtHero}>CONOCE MÁS SOBRE BIG SPORT DATA</h1>
                    </div>
                </div>
                <div className={style.porque}>
                    <div className={style.contRow}>
                        <div className={style.contContenido}>
                            <div className={style.contImages}>
                                <img className={style.imgTwo} src={imgTwo} alt="" />
                                <img className={style.imgAppFiveB} src={imgAppFive} alt="" />
                            </div>
                            <div className={style.contenido}>
                                <img className={style.balonG} src={balon} alt="" />
                                <h3 className={style.sub}>¿QUÉ ES BIG SPORT DATA?</h3>
                                <p className={style.txt}>BigSportData es una plataforma capaz de almacenar, medir, monitorear grandes cantidades de datos deportivos y predecir el rendimiento a través de redes neuronales, inteligencia artificial y machine learning. Logrando tener un histórico de rendimiento en todo el ciclo de vida deportiva, desde su inicio hasta su retiro.</p>
                            </div>
                        </div>
                    </div>
                    <div className={style.contListNum}>
                        <div className={style.contfour}>
                            <div className={style.conttwo}>
                                <div className={style.one}>
                                    <h1 className={style.txtHero}>1.</h1>
                                    <p className={style.txtB}>El deportista u aficionado crea una cuenta con la autorización de su padre o tutor si es menor de edad. Llena su ficha deportiva (fecha de nacimiento, peso, talla, altura, etc.) y ya puede navegar en BigSportData donde podrá buscar a sus amigos o referentes deportivos e invitarlos a ser parte de la red.</p>
                                </div>
                                <div className={style.two}>
                                    <h1 className={style.txtHero}>2.</h1>
                                    <p className={style.txtB}>El deportista llena un formulario de habilidades deportivas, con referencia a los mejores exponentes en el ámbito profesional, lo cual le dará una huella deportiva que lo clasificara en un tipo de deportista ( A,B,C y D).</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.conttree}>
                            <div className={style.tree}>
                                <h1 className={style.txtHero}>3.</h1>
                                <p className={style.txtB}>Habrá cinco pilares del atleta;  fuerza, técnica, resistencia física y velocidad. El cual tendrá que demostrar con un video mínimo de 10s a 30s segundos para dar soporte y validez a su desempeño.</p>
                                <p className={style.txtB}>Estas habilidades tendrán un gráfico con respecto al tiempo, para visualizar, monitorear, predecir y obtener un histórico de rendimiento, lo cual nos permitirá modelar diferentes escenarios y tener herramientas que permitan tomar decisiones referente al atleta, de manera individual o en equipo.</p>
                            </div>
                        </div>
                    </div>
                    <div className={style.four}>
                        <h1 className={style.txtHero}>4.</h1>
                        <p className={style.txtB}>Todos los clubes deportivos, visores, entrenadores, preparadores físicos, medios deportivos podrán ver tu perfil y hacer te comentarios oferta etc.</p>
                    </div>
                </div>
            </div>
            <div className={style.entrenadores}>
                <div className={style.contEntre}>
                    <h3 className={style.subB}>RETOS</h3>
                    <div className={style.contList}>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtL}>Mitigar al máximo el Ocio en la juventud y niñez.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtL}>Que los atletas de alto rendimiento sean los que deben estar representando dignamente a sus delegaciones.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtL}>Llegar a todos y cada uno de los talentos deportivos sin restricciones.</p>
                        </div>
                        <div className={style.listB}>
                            <img className={style.balon} src={balon} alt="" />
                            <p className={style.txtL}>Permitirle al mundo conocer a lo mejor de lo mejor en el ámbito deportivo.</p>
                        </div>
                    </div>
                    <h3 className={style.subB}>MISIÓN</h3>
                    <p className={style.txtB}>Es la nueva propuesta en el mundo deportivo la cual tiene la misión de fortalecer la disciplina deportiva y empoderar a la juventud y niñez a través del alto rendimiento y mejora continua, para que cada uno de ellos muestren lo mejor de sí y cambien su calidad de vida, obteniendo becas deportivas, pruebas etc. Dándoles herramientas que les permitan medir y monitorear su rendimiento de manera continua y sean vistos por todos los clubes deportivos, visores, entrenadores, medios deportivos etc.</p>
                </div>
                <div className={style.contImagesE}>
                    <img className={style.imgTree} src={imgTree} alt="" />
                    <div className={style.imgAppFour}>
                        <img className={style.comillas} src={comillas} alt="" />
                        <h3 className={style.pensamiento}>El pez en la pecera, pequeño siempre será,
                        pero un pez en el océano, su tamaño siempre aumentará.</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nosotros