import React from "react";
import styles from "../Panel.module.css";
import { Bar, Line } from "react-chartjs-2";

function dataRegistrosPorMes(allUsuarios) {
  // Crear un objeto para almacenar los registros por mes y año
  const registrosPorFecha = allUsuarios.reduce((acc, usuario) => {
    const fecha = new Date(usuario.createdAt);
    const mes = fecha.getMonth();
    const año = fecha.getFullYear();
    const key = `${mes}-${año}`;
    
    if (!acc[key]) {
      acc[key] = {
        count: 0,
        label: `${getNombreMes(mes)} ${año}`
      };
    }
    acc[key].count++;
    return acc;
  }, {});

  // Ordenar las fechas cronológicamente
  const fechasOrdenadas = Object.keys(registrosPorFecha)
    .sort((a, b) => {
      const [mesA, añoA] = a.split('-');
      const [mesB, añoB] = b.split('-');
      return new Date(añoA, mesA) - new Date(añoB, mesB);
    });

  return {
    labels: fechasOrdenadas.map(key => registrosPorFecha[key].label),
    data: fechasOrdenadas.map(key => registrosPorFecha[key].count)
  };
}

// Función auxiliar para obtener el nombre del mes
function getNombreMes(mes) {
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  return meses[mes];
}

const Registrados = ({
    allUsuarios,
    allJugadores,
    allReclutadores,
}) => {
  const registrosPorMes = dataRegistrosPorMes(allUsuarios);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            family: 'Oswald',
            size: 14
          },
          color: '#FFFFFF'
        }
      },
      title: {
        display: true,
        font: {
          family: 'Oswald',
          size: 16,
          weight: 'bold'
        },
        color: '#FFFFFF',
        padding: 10
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(165, 165, 165, 0.36)'
        },
        ticks: {
          font: {
            family: 'Oswald',
            size: 14,
            weight: 'bold',
            color: '#FFFFFF'
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            family: 'Oswald'
          }
        }
      }
    }
  };

  return (
    <div className={styles.graficasTotales}>
      <div className={styles.graficaContainer}>
        <Bar
          data={{
            labels: ["Total", "Jugadores", "Reclutadores"],
            datasets: [
              {
                label: "Todos los Usuarios",
                data: [
                  allUsuarios.length - 1,
                  allJugadores.length,
                  allReclutadores.length,
                ],
                backgroundColor: [
                  'rgba(254, 254, 62, 0.8)',
                  'rgba(254, 254, 62, 0.8)',
                  'rgba(254, 254, 62, 0.8)'
                ],
                borderColor: [
                  'rgba(254, 254, 62, 1)',
                  'rgba(254, 254, 62, 1)',
                  'rgba(254, 254, 62, 1)'
                ],
                borderWidth: 1
              },
            ],
          }}
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              title: {
                ...options.plugins.title,
                text: 'Distribución de Usuarios'
              }
            }
          }}
        />
      </div>
      <div className={styles.graficaContainer}>
        <Line
          data={{
            labels: registrosPorMes.labels,
            datasets: [
              {
                label: "Registros",
                data: registrosPorMes.data,
                borderColor: 'rgba(53, 162, 235, 1)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                tension: 0.4,
                fill: true,
                pointStyle: 'circle',
                pointRadius: 6,
                pointHoverRadius: 8
              },
            ],
          }}
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              title: {
                ...options.plugins.title,
                text: 'Tendencia de Registros'
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default Registrados;
