import React, { useState, useRef } from 'react';
import styles from './Noticias.module.css';
import logo from '../../../assets/Logo.svg';
import subirImagen from '../../../assets/SubirImagen.png';
import aplauso from '../../../assets/premium.png';
import mesage from '../../../assets/message.png';
import share from '../../../assets/share.png';
import medallas from '../../../assets/medallaTrue.png';
import axios from 'axios';
import { URL } from '../../../link';
import { useDispatch } from 'react-redux';
import { getNoticias } from '../../../actions';

const Noticias = ({ noticias }) => {
  const user = JSON.parse(localStorage.getItem('dataUser'));
  const [name, setName] = useState('Big Sport Data');
  const [titulo, setTitulo] = useState('Patrocinado');
  const [contenido, setContenido] = useState('Aqui el contenido');
  const [logoNoticia, setLogoNoticia] = useState(user.foto);
  const [imageUpLoad, setImageUpLoad] = useState('');
  const [upLoadVideo, setUpLoadVideo] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [videoLoadingInProgress, setVideoLoadingInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputImgRef = useRef(null);
  const fileInputVideoRef = useRef(null);

  // Nuevo estado para el modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [videoError, setVideoError] = useState("");

  const dispatch = useDispatch();

  const preset_key = 'bqgn8ppn';
  const cloud_name = 'dvwtgluwc';
  const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`;
  const cloudinaryUrlVideo = `https://api.cloudinary.com/v1_1/${cloud_name}/video/upload`;

  const uploadImage = async (file) => {
    setLoadingImage(true);
    setUpLoadVideo('');
    setVideoPreview('');
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', preset_key);
    try {
      const response = await axios.post(cloudinaryUrl, data);
      setImageUpLoad(response.data.secure_url);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingImage(false);
    }
  };

  const uploadVideo = async (file) => {
    // Resetear estados
    if (!file) return;

    setImageUpLoad('');
    setVideoLoadingInProgress(false);
    setLoadingVideo(false);
    setUploadProgress(0);
    setVideoError("");
    
    // Validar formato MP4
    if (!file.type.startsWith('video/mp4')) {
      setVideoError("Solo se permiten videos en formato MP4");
      setModalMessage("Solo se permiten videos en formato MP4");
      setModalVisible(true);
      if (fileInputVideoRef.current) {
        fileInputVideoRef.current.value = null;
      }
      return;
    }

    setVideoLoadingInProgress(true);
    setLoadingVideo(true);

    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;

        if (duration <= 35) {
          const data = new FormData();
          data.append('file', file);
          data.append('upload_preset', "videoUpload");

          try {
            const response = await axios.post(cloudinaryUrlVideo, data, {
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(progress);
              },
            });
            setUpLoadVideo(response.data.public_id);
            setVideoPreview(window.URL.createObjectURL(file));
            setUploadProgress(100);
            setTimeout(() => {
              setVideoLoadingInProgress(false);
              setLoadingVideo(false);
              setUploadProgress(0);
            }, 1000);
          } catch (error) {
            console.log(error);
            setVideoError("Error al subir el video. Intenta nuevamente.");
            setModalMessage("Error al subir el video. Intenta nuevamente.");
            setModalVisible(true);
            setVideoLoadingInProgress(false);
            setLoadingVideo(false);
            setUploadProgress(0);
          }
        } else {
          setVideoError("El video no puede superar los 35 segundos");
          setModalMessage("El video no puede superar los 35 segundos");
          setModalVisible(true);
          setVideoLoadingInProgress(false);
          setLoadingVideo(false);
          setUploadProgress(0);
        }
      };

      video.src = window.URL.createObjectURL(file);
    } catch (error) {
      console.log(error);
      setVideoError("Error al procesar el video");
      setModalMessage("Error al procesar el video");
      setModalVisible(true);
      setVideoLoadingInProgress(false);
      setLoadingVideo(false);
      setUploadProgress(0);
    }
  };

  const upLoadLogo = async (e) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('file', file);
    data.append('upload_preset', preset_key);
    try {
      const response = await axios.post(cloudinaryUrl, data);
      setLogoNoticia(response.data.secure_url);
    } catch (error) {
      console.log(error);
    }
  };

  const enviarNoticia = async () => {
    const data = {
      titulo: titulo,
      mensaje: contenido,
      img: imageUpLoad,
      video: upLoadVideo,
      empresa: name,
      logo: logoNoticia,
      userID: user.id,
      tipo: 'noticia',
    };
    try {
      const res = await axios.post(`${URL}/noticia/nueva`, data);
      dispatch(getNoticias());
      console.log(res.data);

      // Mostrar el modal de éxito
      setModalMessage('¡Tu noticia fue enviada con éxito!');
      setModalVisible(true);

      // Resetear los campos del formulario
      setName('Big Sport Data');
      setTitulo('Patrocinado');
      setContenido('Aquí el contenido');
      setLogoNoticia(user.foto);
      setImageUpLoad('');
      setUpLoadVideo('');
      setVideoPreview('');
    } catch (error) {
      console.log(error);
      setModalMessage('Hubo un error al enviar la noticia, por favor intenta nuevamente.');
      setModalVisible(true);
    }
  };

  const Modal = ({ message, onClose }) => {
    return (
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <p>{message}</p>
          <button onClick={onClose} className={styles.btn}>
            Cerrar
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Administrar Noticias</h2>
      <div className={styles.content}>
        <div className={styles.formSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.subtitle}>Nueva Noticia</h3>
          </div>
          
          <div className={styles.formContainer}>
            <div className={styles.formGroup}>
              <div className={styles.logoUpload}>
                <img
                  src={logoNoticia || subirImagen}
                  alt="Logo"
                  className={styles.logoPreview}
                  onClick={() => document.getElementById('fileLogo').click()}
                />
                <input
                  type="file"
                  id="fileLogo"
                  onChange={upLoadLogo}
                  accept="image/*"
                  style={{ display: 'none' }}
                />
                <span className={styles.uploadText}>Click para cambiar logo</span>
              </div>
              
              <div className={styles.inputGroup}>
                <label className={styles.label}>Nombre de la empresa</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={styles.input}
                  placeholder="Big Sport Data"
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.inputGroup}>
                <label className={styles.label}>Título</label>
                <input
                  type="text"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                  className={styles.input}
                  placeholder="Patrocinado"
                />
              </div>

              <div className={styles.inputGroup}>
                <label className={styles.label}>Contenido</label>
                <textarea
                  value={contenido}
                  onChange={(e) => setContenido(e.target.value)}
                  className={styles.textarea}
                  placeholder="Aquí el contenido"
                />
              </div>
            </div>

            <div className={styles.mediaSection}>
              <label className={styles.label}>Multimedia</label>
              <div className={styles.mediaButtons}>
                <button
                  className={`${styles.btn} ${imageUpLoad && styles.active}`}
                  onClick={() => document.getElementById('fileInput').click()}
                  disabled={loadingImage || loadingVideo}
                >
                  {loadingImage ? 'Cargando...' : 'Subir Imagen'}
                </button>
                <input
                  type="file"
                  id="fileInput"
                  onChange={(e) => uploadImage(e.target.files[0])}
                  accept="image/*"
                  style={{ display: 'none' }}
                />

                <div className={styles.videoUploadContainer}>
                  <button
                    className={`${styles.btn} ${upLoadVideo && styles.active}`}
                    onClick={() => document.getElementById('fileVideo').click()}
                    disabled={loadingVideo || loadingImage}
                  >
                    {loadingVideo 
                      ? `Cargando ${uploadProgress}%` 
                      : upLoadVideo 
                        ? 'Video Cargado' 
                        : 'Subir Video'
                    }
                  </button>
                  {loadingVideo && (
                    <div className={styles.progressBar}>
                      <div 
                        className={styles.progressFill} 
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="fileVideo"
                    ref={fileInputVideoRef}
                    onChange={(e) => uploadVideo(e.target.files[0])}
                    accept="video/mp4"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              
              {videoError && (
                <div className={styles.errorMessage}>
                  {videoError}
                </div>
              )}
            </div>

            <button 
              className={styles.submitBtn}
              onClick={enviarNoticia}
            >
              Publicar Noticia
            </button>
          </div>
        </div>

        <div className={styles.previewSection}>
          <div className={styles.sectionHeader}>
            <h3 className={styles.subtitle}>Vista Previa</h3>
          </div>
          
          <div className={styles.previewCard}>
            <div className={styles.cardHeader}>
              <img src={logoNoticia || logo} alt="Logo" className={styles.previewLogo} />
              <div className={styles.headerInfo}>
                <h4 className={styles.previewTitle}>{name}</h4>
                <span className={styles.previewSubtitle}>{titulo}</span>
              </div>
              <span className={styles.timestamp}>Hace 2 horas</span>
            </div>

            <div className={styles.cardContent}>
              <p className={styles.previewText}>{contenido}</p>
              {imageUpLoad && (
                <img src={imageUpLoad} alt="Contenido" className={styles.previewMedia} />
              )}
              {videoPreview && (
                <video src={videoPreview} controls className={styles.previewMedia}></video>
              )}
            </div>

            <div className={styles.cardFooter}>
              <div className={styles.reaction}>
                <img src={aplauso} alt="Aplausos" className={styles.reactionIcon} />
                <span>1</span>
              </div>
              <div className={styles.reaction}>
                <img src={mesage} alt="Mensajes" className={styles.reactionIcon} />
                <span>4</span>
              </div>
              <div className={styles.reaction}>
                <img src={share} alt="Compartir" className={styles.reactionIcon} />
                <span>10</span>
              </div>
              <div className={styles.reaction}>
                <img src={medallas} alt="Medallas" className={styles.reactionIcon} />
                <span>5</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <p>{modalMessage}</p>
            <button onClick={() => setModalVisible(false)} className={styles.btn}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Noticias;
