import React, { useEffect } from 'react'
import styles from "./Panel.module.css"
import Chart from 'chart.js/auto';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { dataRegistros } from './actions';
import Registrados from './componentes/Registrados';
import MapaMexico from './componentes/MapaMexico';


const Panel = ({allJugadores, allReclutadores, allUsuarios, usuariosDesactivos, usuariosActivos}) => {
    useEffect(() => {},[allJugadores, allReclutadores, usuariosDesactivos, usuariosActivos])

    console.log("allReclutadores", allReclutadores)

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '75%', // Hace el anillo más delgado
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    padding: 20,
                    font: {
                        family: 'Oswald',
                        size: 14,
                        weight: 'bold'
                    },
                    color: '#FFFFFF',
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Estadisticas</h2>
            <div className={styles.totales}>
                <div className={styles.dataTotales}>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Total de Usuarios:</h4>
                        <h3 className={styles.num}>{allUsuarios.length - 1}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Usuarios:</h4>
                        <h3 className={styles.num}>{allJugadores.length}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Reclutadores:</h4>
                        <h3 className={styles.num}>{allReclutadores.length}</h3>
                    </div>
                </div>
                <Registrados allUsuarios={allUsuarios} allJugadores={allJugadores} allReclutadores={allReclutadores} />
            </div>
            <div className={styles.totales}>
            <h3 className={styles.title}>Usuarios</h3>
                <div className={styles.dataTotales}>
                <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Inactivos:</h4>
                        <h3 className={styles.num}>{usuariosDesactivos.length}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Activos:</h4>
                        <h3 className={styles.num}>{usuariosActivos.length-1}</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Premium:</h4>
                        <h3 className={styles.num}>3</h3>
                    </div>
                    <div className={styles.Uregistrados}>
                        <h4 className={styles.subTitle}>Free:</h4>
                        <h3 className={styles.num}>3</h3>
                    </div>
                </div>
                <div className={styles.graficasTotales}>
                    <div className={styles.graficaContainer}>
                        <Doughnut
                            data={{
                                labels: ['Usuarios Bloqueados', 'Usuarios Activos'],
                                datasets: [{
                                    data: [usuariosDesactivos.length, usuariosActivos.length-1],
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.8)',
                                        'rgba(75, 192, 192, 0.8)'
                                    ],
                                    hoverBackgroundColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(75, 192, 192, 1)'
                                    ],
                                    borderRadius: 20,
                                }]
                            }}
                            options={doughnutOptions}
                        />
                    </div>
                    <div className={styles.graficaContainer}>
                        <Doughnut
                            data={{
                                labels: ['Reclutadores Bloqueados', 'Reclutadores Free', 'Reclutadores Premium'],
                                datasets: [{
                                    data: [1, 2, 3],
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.8)',
                                        'rgba(54, 162, 235, 0.8)',
                                        'rgba(255, 206, 86, 0.8)'
                                    ],
                                    hoverBackgroundColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)'
                                    ],
                                    borderRadius: 20,
                                }]
                            }}
                            options={doughnutOptions}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.totales}>
                <h3 className={styles.title}>Distribución Geográfica de Usuarios</h3>
                <div className={styles.mapContent}>
                    <MapaMexico 
                        allJugadores={allJugadores} 
                        allReclutadores={allReclutadores}
                    />
                </div>
            </div>
        </div>
    )
}

export default Panel
