// SearchBar.jsx
import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import { estadosOptions } from '../../funciones';

const SearchBar = ({ 
  handleSearch, 
  handleReset, 
  handleOrder, 
  order, 
  handleRolFilter, 
  handleEstadoFilter,
  handleEstadoRegionFilter,
  handleEdadFilter,
  selectedRol,
  selectedEstado,
  selectedEstadoRegion,
  edadRange,
  handleClearFilter
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [edadMin, setEdadMin] = useState(edadRange.min);
  const [edadMax, setEdadMax] = useState(edadRange.max);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchTerm);
    }
  };

  const handleResetClick = () => {
    setSearchTerm('');
    setEdadMin(0);
    setEdadMax(100);
    handleReset();
  };

  const handleRolChange = (e) => {
    const rol = e.target.value;
    handleRolFilter(rol);
  };

  const handleEstadoChange = (e) => {
    const estado = e.target.value;
    handleEstadoFilter(estado);
  };

  const handleEstadoRegionChange = (e) => {
    const estadoRegion = e.target.value;
    handleEstadoRegionFilter(estadoRegion);
  };

  const handleEdadChange = (e) => {
    const { name, value } = e.target;
    if (name === "edadMin") {
      setEdadMin(Number(value));
      handleEdadFilter(Number(value), edadMax);
    } else {
      setEdadMax(Number(value));
      handleEdadFilter(edadMin, Number(value));
    }
  };

  const handleFilterClear = (filterType) => {
    switch (filterType) {
      case 'search':
        setSearchTerm('');
        handleClearFilter(filterType);
        break;
      case 'edad':
        setEdadMin(0);
        setEdadMax(100);
        handleClearFilter(filterType);
        break;
      default:
        handleClearFilter(filterType);
    }
  };

  const getActiveFilters = () => {
    const filters = [];
    if (searchTerm) filters.push({ type: 'search', value: searchTerm, label: `Búsqueda: ${searchTerm}` });
    if (selectedRol !== 'todos') filters.push({ type: 'rol', value: selectedRol, label: `Rol: ${selectedRol}` });
    if (selectedEstado !== 'todos') filters.push({ type: 'estado', value: selectedEstado, label: `Registro: ${selectedEstado}` });
    if (selectedEstadoRegion !== 'todos') filters.push({ type: 'region', value: selectedEstadoRegion, label: `Estado: ${selectedEstadoRegion}` });
    if (edadMin > 0 || edadMax < 100) filters.push({ type: 'edad', value: `${edadMin}-${edadMax}`, label: `Edad: ${edadMin}-${edadMax} años` });
    return filters;
  };

  return (
    <div className={styles.searchBar}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchInputs}>
          <div className={styles.searchGroup}>
            <input
              className={styles.inputText}
              type="text"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
            <button 
              className={`${styles.btn} ${styles.searchBtn}`} 
              onClick={() => handleSearch(searchTerm)}
            >
              Buscar
            </button>
          </div>
          <select 
            className={styles.selectFilter}
            value={selectedRol}
            onChange={handleRolChange}
          >
            <option value="todos">Todos los roles</option>
            <option value="Jugador">Jugador</option>
            <option value="Otro">Otro</option>
          </select>
          <select 
            className={styles.selectFilter}
            value={selectedEstado}
            onChange={handleEstadoChange}
          >
            <option value="todos">Estado de registro</option>
            <option value="completado">Completados</option>
            <option value="incompleto">Incompletos</option>
          </select>
          <select 
            className={styles.selectFilter}
            value={selectedEstadoRegion}
            onChange={handleEstadoRegionChange}
          >
            <option value="todos">Todos los estados</option>
            {estadosOptions.map(option => (
              option.props.value !== "0" && (
                <option key={option.props.value} value={option.props.value}>
                  {option.props.children}
                </option>
              )
            ))}
          </select>
          <button className={`${styles.btn} ${styles.orderBtn}`} onClick={handleOrder}>
            Ordenar {order === "asc" ? "Z - A" : "A - Z"}
          </button>
        </div>
        <div className={styles.rangeContainer}>
          <div className={styles.rangeGroup}>
            <label>Edad: {edadMin} - {edadMax} años</label>
            <div className={styles.rangeInputs}>
              <input
                type="range"
                name="edadMin"
                min="0"
                max="100"
                value={edadMin}
                onChange={handleEdadChange}
                className={styles.rangeSlider}
              />
              <input
                type="range"
                name="edadMax"
                min="0"
                max="100"
                value={edadMax}
                onChange={handleEdadChange}
                className={styles.rangeSlider}
              />
            </div>
          </div>
        </div>
        <div className={styles.filtersSection}>
          <div className={styles.filterActions}>
            <button 
              className={`${styles.btn} ${styles.clearBtn}`} 
              onClick={handleResetClick}
            >
              Limpiar filtros
            </button>
          </div>
          <div className={styles.activeTags}>
            {getActiveFilters().map((filter) => (
              <div key={`${filter.type}-${filter.value}`} className={styles.filterTag}>
                <span>{filter.label}</span>
                <button
                  className={styles.removeTag}
                  onClick={() => handleFilterClear(filter.type)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
