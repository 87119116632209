import React, { useState } from 'react'
import style from "./ModalBSD.module.css"
import Score from "./Componentes/Score.jsx"
import ModalScore from "./Componentes/ModalScore.jsx"

const ModalBSD = ({
    user,
    getScoresByVisitor,
    getEntrenamientosByVisitor,
    isVisitor,
    entrenamientos,
    setModalScoreBSD,
    metricas
}) => {

  const [detallesBool, setDetallesBool] = useState(false);
  const [dateSelected, setDateSelected] = useState("")

  const openModalScore = (date) => {
    const cleanDate = date.replace('Fecha: ', '');
    setDateSelected(cleanDate);
    setDetallesBool(true);
  }

  return (
    <div className={style.modalEntrenamiento}>
      <div className={style.modalContent}>
        {!detallesBool ? <Score 
            user={user}
            getScoresByVisitor={getScoresByVisitor}
            getEntrenamientosByVisitor={getEntrenamientosByVisitor}
            isVisitor={isVisitor}
            openModalScore={openModalScore}
            setModalScoreBSD={setModalScoreBSD}
            entrenamientos={entrenamientos}
            metricas={metricas}
        /> : <ModalScore
            user={user}
            setDetallesBool={setDetallesBool}
            dateSelected={dateSelected}
        />}
      </div>
    </div>
  )
}

export default ModalBSD
