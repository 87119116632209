import React, { useMemo, useState } from 'react';
import { ComposableMap, Geographies, Geography, Tooltip } from 'react-simple-maps';
import styles from '../Panel.module.css';
import { mexicoStates } from '../data/mexico-states';

const MapaMexico = ({ allJugadores, allReclutadores }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Mapa de conversión entre códigos del GeoJSON y los códigos de tu base de datos
  const codigosEstados = {
    "MX-AGU": "AGS", // Aguascalientes
    "MX-BCN": "BC",  // Baja California
    "MX-BCS": "BCS", // Baja California Sur
    "MX-CAM": "CAMP", // Campeche
    "MX-CHP": "CHIS", // Chiapas
    "MX-CHH": "CHIH", // Chihuahua
    "MX-CMX": "CDMX", // Ciudad de México
    "MX-COA": "COAH", // Coahuila
    "MX-COL": "COL", // Colima
    "MX-DUR": "DGO", // Durango
    "MX-GUA": "GTO", // Guanajuato
    "MX-GRO": "GRO", // Guerrero
    "MX-HID": "HGO", // Hidalgo
    "MX-JAL": "JAL", // Jalisco
    "MX-MEX": "MEX", // Estado de México
    "MX-MIC": "MICH", // Michoacán
    "MX-MOR": "MOR", // Morelos
    "MX-NAY": "NAY", // Nayarit
    "MX-NLE": "NL",  // Nuevo León
    "MX-OAX": "OAX", // Oaxaca
    "MX-PUE": "PUE", // Puebla
    "MX-QUE": "QRO", // Querétaro
    "MX-ROO": "QROO", // Quintana Roo
    "MX-SLP": "SLP", // San Luis Potosí
    "MX-SIN": "SIN", // Sinaloa
    "MX-SON": "SON", // Sonora
    "MX-TAB": "TAB", // Tabasco
    "MX-TAM": "TAMPS", // Tamaulipas
    "MX-TLA": "TLAX", // Tlaxcala
    "MX-VER": "VER", // Veracruz
    "MX-YUC": "YUC", // Yucatán
    "MX-ZAC": "ZAC"  // Zacatecas
  };

  const estadisticasPorEstado = useMemo(() => {
    const stats = {};
    
    // Inicializar todos los estados con contadores en 0
    Object.values(codigosEstados).forEach(code => {
      stats[code] = {
        jugadores: 0,
        reclutadores: 0,
        total: 0
      };
    });

    // Contar jugadores por estado
    allJugadores.forEach(jugador => {
      if (jugador.origen) {
        stats[jugador.origen].jugadores += 1;
        stats[jugador.origen].total += 1;
      }
    });

    // Contar reclutadores por estado
    allReclutadores.forEach(reclutador => {
      if (reclutador.origen) {
        stats[reclutador.origen].reclutadores += 1;
        stats[reclutador.origen].total += 1;
      }
    });

    return stats;
  }, [allJugadores, allReclutadores]);

  return (
    <div className={styles.mapaContainer}>
      {tooltipContent && (
        <div 
          className={styles.tooltip}
          style={{
            left: tooltipPosition.x,
            top: tooltipPosition.y,
          }}
        >
          {tooltipContent}
        </div>
      )}
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 1300,
          center: [-102, 23],
        }}
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "1100px",
          margin: "0 auto"
        }}
      >
        <Geographies geography={mexicoStates}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const geoId = geo.properties.id;
              const estadoCode = codigosEstados[geoId];
              const stats = estadisticasPorEstado[estadoCode] || { jugadores: 0, reclutadores: 0, total: 0 };
              
              return (
                <Geography
                  key={geoId}
                  geography={geo}
                  fill={stats.total > 0 ? "#E4FD3F" : "#262626"}
                  stroke="#E4FD3F33"
                  strokeWidth={0.5}
                  onMouseEnter={(evt) => {
                    const { clientX, clientY } = evt;
                    setTooltipContent(
                      `${geo.properties.name}:
                      \nJugadores: ${stats.jugadores}
                      \nReclutadores: ${stats.reclutadores}
                      \nTotal: ${stats.total}`
                    );
                    setTooltipPosition({ x: clientX, y: clientY });
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    default: { outline: "none" },
                    hover: {
                      fill: "#E4FD3F",
                      cursor: "pointer"
                    }
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default MapaMexico; 