import styles from "./User.module.css";
import { formatoLegible } from "../funciones.js";
import ModalMetricaComparativo from "../ModalMetrica/ModalMetricaComparativo.jsx";
import ModalPosicionComparativo from "../ModalMetrica/ModalPosicionComparativo.jsx";
import { useState } from "react";
import PosicionVisitor from "./components/PosicionVisitor.jsx";
import HabilidadesVisitor from "./components/HabilidadesVisitor.jsx";
import MetricasVisitor from "./components/MetricasVisitor.jsx";
import { getPreviousMetric } from "./utils/getPreviousMetric";
import subir from "../../assets/subir.png";
import bajar from "../../assets/bajar.png";

const UserVisitor = ({ 
  user, 
  handleOpenModalBSD,
  scores,
  setNavSecondActive,
  navSecondActive }) => {
  const userLocal = JSON.parse(localStorage.getItem("dataUser"));
  const [tab, setTab] = useState("habilidades");
  const [radarBool, setRadarBool] = useState(false);
  const [posicionBool, setPosicionBool] = useState(false);
  const previousMetric = getPreviousMetric(scores);

  const posicionesLegibles = user.posicionesSeleccionadas.map(formatoLegible);

  const handleRadar = () => {
    setRadarBool(!radarBool);
    setNavSecondActive(!navSecondActive);
    window.scrollTo(0, 0);
  };

  const handlePosicion = () => {
    setPosicionBool(!posicionBool);
    setNavSecondActive(!navSecondActive);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.conteiner}>
      <div className={styles.contDesempeno}>
        <p className={styles.seccion}>DESEMPEÑO GENERAL</p>
        <div className={styles.desempeno}>
          <div className={styles.contData}>
            <div className={styles.contBSD}>
              <div className={styles.bsd}>
                <p className={styles.bsdText}>BSD SCORE</p>
                <p className={styles.bsdValor}>{user?.scores?.[0]?.valor ?? 0}</p>
              </div>
            </div>
          </div>
          <div className={styles.anterior}>
            <p className={styles.anteriorText}>{previousMetric.tiempo}</p>
            <div className={styles.contValor}>
              <img className={styles.icono} src={previousMetric.valor > user?.scores?.[0]?.valor ? bajar : subir} alt="subir" />
              <p className={styles.valor}>{previousMetric.valor}</p>
            </div>
          </div>
          <button className={styles.btnResumen} onClick={() => handleOpenModalBSD(true)}>VER RESUMEN</button>
        </div>
      </div>
      <PosicionVisitor
        user={user}
        handlePosicion={handlePosicion}
        posicionesLegibles={posicionesLegibles}
        userLocal={userLocal}
      />
      <HabilidadesVisitor user={user} />
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>METRICAS PERSONALES</h3>
        {userLocal.rol === "Jugador" && (
          <p className={styles.btnTxt} onClick={handleRadar}>
            COMPARAR
          </p>
        )}
      </div>
      <div className={styles.tab}>
        <div
          className={
            tab === "habilidades" ? styles.tabItemActive : styles.tabItem
          }>
          <p className={styles.tabText}>HABILIDADES</p>
        </div>
      </div>
        <MetricasVisitor
          user={user}
          userLocal={userLocal}
          handleRadar={handleRadar}
        />
      {radarBool && (
        <ModalMetricaComparativo user={user} handleRadar={handleRadar} />
      )}
      {posicionBool && (
        <ModalPosicionComparativo user={user} handlePosicion={handlePosicion} />
      )}
    </div>
  );
};

export default UserVisitor;
