import React, { useEffect, useState } from 'react';
import styles from './Amigos.module.css';
import Card from '../Card/Card';
import ModalNotificaciones from '../ModalNotificaciones/ModalNotificaciones';
import 'animate.css';
import btnX from '../../assets/btnX.png'; 
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ModalUser from '../ModalUser/ModalUser';
import iconFilter from "../../assets/iconFilter.png"
import { posicionOptions, habilidadesOptions, filtrarUsuarios, buscarPorNombre, meSigue } from '../funciones'; 

const Amigos = ({ localUser, allJugadores, seguidores, seguidos, dispatcherAmigos, inVisitor, seguir, notificacionesBool, dejarSeguir, logOut, userBool, clickMessage, inEdit,desactivarNotificacion }) => {

  const [buscadorBool, setBuscadorBool] = useState(true)
  const [seguidoresBool, setSeguidoresBool] = useState(false)
  const [seguidosBool, setSeguidosBool] = useState(false)

  const [filterBool, setFilterBool] = useState(false)

  const [posicion, setPosicion] = useState(JSON.parse(localStorage.getItem("posicion")) || []);
  const [habilidad, setHabilidad] = useState(JSON.parse(localStorage.getItem("habilidad")) || []);
  const [edadRange, setEdadRange] = useState([JSON.parse(localStorage.getItem("edadMin")) || 0, JSON.parse(localStorage.getItem("edadMax")) || 100]);
  const [alturaRange, setAlturaRange] = useState([JSON.parse(localStorage.getItem("alturaMin")) || 0, JSON.parse(localStorage.getItem("alturaMax")) || 250]);
  const [bsdRange, setBsdRange] = useState([JSON.parse(localStorage.getItem("bsdMin")) || 0, JSON.parse(localStorage.getItem("bsdMax")) || 100])
  const [pie, setPie] = useState(JSON.parse(localStorage.getItem("pie")) || "")

  const [nombre, setNombre] = useState("")

  const [usuariosFiltrados, setUsuariosFiltrados] = useState([])
  const [usuriosPorNombre, setUsuariosPorNombre] = useState([])

  const [seguidoresFiltrados, setSeguidoresFiltrados] = useState([])
  const [seguidoresPorNombre, setSeguidoresPorNombre] = useState([])

  const [seguidosFiltrados, setSeguidosFiltrados] = useState([])
  const [seguidosPorNombre, setSeguidosPorNombre] = useState([])

  const verificarLocalStorage = () => {
    if (localStorage.getItem("posicion") === null) {
      localStorage.setItem("posicion", JSON.stringify([]));
    }
    if (localStorage.getItem("habilidad") === null) {
      localStorage.setItem("habilidad", JSON.stringify([]));
    }
    if (localStorage.getItem("edadMin") === null) {
      localStorage.setItem("edadMin", JSON.stringify(0));
    }
    if (localStorage.getItem("edadMax") === null) {
      localStorage.setItem("edadMax", JSON.stringify(100));
    }
    if (localStorage.getItem("alturaMin") === null) {
      localStorage.setItem("alturaMin", JSON.stringify(0));
    }
    if (localStorage.getItem("alturaMax") === null) {
      localStorage.setItem("alturaMax", JSON.stringify(220));
    }
    if (localStorage.getItem("pie") === null) {
      localStorage.setItem("pie", JSON.stringify(""));
    }
    if(localStorage.getItem("bsdMin") === null) {
       localStorage.setItem("bsdMin", JSON.stringify(0));
    }
     if(localStorage.getItem("bsdMax") === null) {
       localStorage.setItem("bsdMax", JSON.stringify(100))
    }
  }

  const handleEdadChange = (value) => {
    setEdadRange(value);
    localStorage.setItem("edadMin", JSON.stringify(value[0]));
    localStorage.setItem("edadMax", JSON.stringify(value[1]));
  };

  const handleAlturaChange = (value) => {
    setAlturaRange(value);
    localStorage.setItem("alturaMin", JSON.stringify(value[0]));
    localStorage.setItem("alturaMax", JSON.stringify(value[1]));
  };

  const handleBsdChange = (value) => {
    setBsdRange(value);
    localStorage.setItem("bsdMin", JSON.stringify(value[0]));
    localStorage.setItem("bsdMax", JSON.stringify(value[1]));
  }

  const handlePosicionChange = (e) => {
    const selectedPosicion = e.target.value;
    if (!posicion.includes(selectedPosicion)) {
      setPosicion([...posicion, selectedPosicion]);
      localStorage.setItem("posicion", JSON.stringify([...posicion, selectedPosicion]));
    }
  };

  const handleHabilidadChange = (e) => {
    const selecteHabilidad = e.target.value;
    if (!habilidad.includes(selecteHabilidad)) {
      setHabilidad([...habilidad, selecteHabilidad]);
      localStorage.setItem("habilidad", JSON.stringify([...habilidad, selecteHabilidad]));
    }
  }

  const selectPie = (pie) => {
    setPie(pie)
    localStorage.setItem("pie", JSON.stringify(pie))
  }

  const deletePosicion = (posi) => {
    setPosicion(posicion.filter((p) => p !== posi));
    localStorage.setItem("posicion", JSON.stringify(posicion.filter((p) => p !== posi)));
  }

  const deleteHabilidad = (hab) => {
    setHabilidad(habilidad.filter((h) => h !== hab));
    localStorage.setItem("habilidad", JSON.stringify(habilidad.filter((h) => h !== hab)));
  }


  const railStyle = {
    backgroundColor: 'rgba(38, 38, 38, 1)',
  };

  const trackStyle = {
    backgroundColor: 'rgba(228, 253, 63, 1)',
  };

  const handleStyle = {
    borderColor: 'rgba(228, 253, 63, 1)',
    backgroundColor: 'rgba(228, 253, 63, 1)',
  };


  const handleBuscador = () => {
    setBuscadorBool(true);
    setSeguidoresBool(false);
    setSeguidosBool(false);
  }

  const handleSeguidores = () => {
    setSeguidoresBool(true);
    setSeguidosBool(false);
    setBuscadorBool(false);
  }

  const handleSeguidos = () => {
    setSeguidosBool(true);
    setSeguidoresBool(false);
    setBuscadorBool(false);
  }

  const limpiarFiltros = () => {
    setPosicion([]);
    setHabilidad([]);
    setEdadRange([0, 100]);
    setAlturaRange([0, 250]);
    setPie("")
    setBsdRange([0, 100])
    localStorage.setItem("posicion", JSON.stringify([]));
    localStorage.setItem("habilidad", JSON.stringify([]));
    localStorage.setItem("edadMin", JSON.stringify(0));
    localStorage.setItem("edadMax", JSON.stringify(100));
    localStorage.setItem("alturaMin", JSON.stringify(0));
    localStorage.setItem("alturaMax", JSON.stringify(250));
    localStorage.setItem("pie", JSON.stringify(""))
    localStorage.setItem("bsdMin", JSON.stringify(0))
    localStorage.setItem("bsdMax", JSON.stringify(100))
  }

  useEffect(() => {
    dispatcherAmigos();
    verificarLocalStorage()
  }, []);
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (notificacionesBool && event.target.closest('.modal-notificaciones') === null || userBool && event.target.closest('.modal-user') === null) {
        desactivarNotificacion();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [notificacionesBool, desactivarNotificacion, userBool]);

  console.log("allJugadores", allJugadores)
  console.log("seguidores", seguidores)
  console.log("seguidos", seguidos)


  useEffect(() => {
    setUsuariosFiltrados(filtrarUsuarios(allJugadores, { posiciones: posicion, habilidades: habilidad, alturaMin: alturaRange[0], alturaMax: alturaRange[1], edadMin: edadRange[0], edadMax: edadRange[1], pie: pie, scoreMin: bsdRange[0], scoreMax: bsdRange[1] }))
    setSeguidoresFiltrados(filtrarUsuarios(seguidores, { posiciones: posicion, habilidades: habilidad, alturaMin: alturaRange[0], alturaMax: alturaRange[1], edadMin: edadRange[0], edadMax: edadRange[1], pie: pie, scoreMin: bsdRange[0], scoreMax: bsdRange[1] }))
    setSeguidosFiltrados(filtrarUsuarios(seguidos, { posiciones: posicion, habilidades: habilidad, alturaMin: alturaRange[0], alturaMax: alturaRange[1], edadMin: edadRange[0], edadMax: edadRange[1], pie: pie, scoreMin: bsdRange[0], scoreMax: bsdRange[1] }))
  }, [bsdRange, posicion, habilidad, edadRange, alturaRange, pie, allJugadores, seguidores, seguidos])

  useEffect(() => {
    setUsuariosPorNombre(buscarPorNombre(usuariosFiltrados, nombre))
    setSeguidoresPorNombre(buscarPorNombre(seguidoresFiltrados, nombre))
    setSeguidosPorNombre(buscarPorNombre(seguidosFiltrados, nombre))
  }, [nombre, usuariosFiltrados])


  return (
    <div className={styles.conteiner}>
      <div className={!filterBool ? styles.contMenu : styles.contMenuActive}>
        <h1 className={styles.title}>COMUNIDAD</h1>
        <div className={styles.contBtn}>
          <div className={buscadorBool ? styles.btnTxtActive : styles.btnTxt} onClick={handleBuscador}>
            <p className={styles.txtB}>Buscador</p>
          </div>
          <div className={seguidoresBool ? styles.btnTxtActive : styles.btnTxt} onClick={handleSeguidores}>
            <p className={styles.txtB}>Seguidores</p>
          </div>
          <div className={seguidosBool ? styles.btnTxtActive : styles.btnTxt} onClick={handleSeguidos}>
            <p className={styles.txtB}>Seguidos</p>
          </div>
        </div>
        <div className={styles.contFiltros}>
          <p className={styles.back} onClick={() => setFilterBool(false)}>{`<Regresar`}</p>
          <div className={styles.contTitle}>
            <h3 className={styles.subTitle}>FILTROS DE BÚSQUEDA</h3>
            <p className={styles.txtA} onClick={limpiarFiltros}>LIMPIAR</p>
          </div>
          <h4 className={styles.parametro}>Posición</h4>
          <select className={styles.inputCampo} onChange={handlePosicionChange} value={posicion[0]}>
            {posicionOptions}
          </select>
          <div className={styles.contPosicones}>
            {posicion.map(posi => (
              <div className={styles.contPossicionSelected}>
                <p className={styles.posicion}>{posi}</p>
                <img className={styles.btnX} src={btnX} alt="" onClick={() => deletePosicion(posi)} />
              </div>
            ))}
          </div>
          <h4 className={styles.parametro}>Habilidades destacadas</h4>
          <select className={styles.inputCampo} onChange={handleHabilidadChange} value={habilidad[0]}>
            {habilidadesOptions}
          </select>
          <div className={styles.contPosicones}>
            {habilidad.map(habi => (
              <div className={styles.contPossicionSelected}>
                <p className={styles.posicion}>{habi}</p>
                <img className={styles.btnX} src={btnX} alt="" onClick={() => deleteHabilidad(habi)} />
              </div>
            ))}
          </div>
          <h4 className={styles.parametro}>Edad</h4>
          <div className={styles.slider}>
            <Slider
              min={0}
              max={100}
              range
              defaultValue={[0, 100]}
              value={edadRange}
              onChange={handleEdadChange}
              railStyle={railStyle}
              trackStyle={trackStyle}
              handleStyle={handleStyle}
            />
            <p className={styles.valor}>{edadRange[0]} a {edadRange[1]} años</p>
          </div>
          <h4 className={styles.parametro}>Altura</h4>
          <div className={styles.slider}>
            <Slider
              min={0}
              max={220}
              range
              defaultValue={[0, 250]}
              value={alturaRange}
              onChange={handleAlturaChange}
              railStyle={railStyle}
              trackStyle={trackStyle}
              handleStyle={handleStyle}
            />
            <p className={styles.valor}>{alturaRange[0]} a {alturaRange[1]} cm</p>
          </div>
          <h4 className={styles.parametro}>BSD Score</h4>
          <div className={styles.slider}>
            <Slider
              min={0}
              max={100}
              range
              defaultValue={[0, 100]}
              value={bsdRange}
              onChange={handleBsdChange}
              railStyle={railStyle}
              trackStyle={trackStyle}
              handleStyle={handleStyle}
            />
            <p className={styles.valor}>{bsdRange[0]} a {bsdRange[1]}</p>
          </div>
          <h4 className={styles.parametro}>Pie</h4>
          <div className={styles.contBotones}>
            <button className={pie === "Derecho" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Derecho")}>Derecho</button>
            <button className={pie === "Izquierdo" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Izquierdo")}>Izquierdo</button>
            <button className={pie === "Ambos" ? styles.botonSelected : styles.boton} onClick={() => selectPie("Ambos")}>Ambos</button>
          </div>
        </div>
      </div>
      <div>
        <div className={filterBool ? styles.blockDesactive : styles.menuAnd}>
          <div className={seguidosBool ? styles.opcSelected : styles.opc} onClick={handleSeguidos}>
            <p className={styles.txtOpc}>Seguidos</p>
          </div>
          <div className={seguidoresBool ? styles.opcSelected : styles.opc} onClick={handleSeguidores}>
            <p className={styles.txtOpc}>Seguidores</p>
          </div>
          <div className={buscadorBool ? styles.opcSelected : styles.opc} onClick={handleBuscador}>
            <p className={styles.txtOpc}>Buscar</p>
          </div>
        </div>
        {buscadorBool && (
          <div className={filterBool ? styles.blockDesactive : styles.contBuscador}>
            <div className={styles.contInput}>
              <input className={styles.input} type="text" placeholder='Buscar por nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
              <img className={styles.iconFilter} src={iconFilter} alt="" onClick={() => setFilterBool(true)}/>
            </div>
            <div className={styles.recomendados}>
              {usuriosPorNombre.map((user) => (
                user.id !== localUser.id && (
                  <Card
                    key={user.id}
                    status={"Recomendadas"}
                    id={user.id}
                    nombre={user.nombre}
                    posicion={user.posicion}
                    origen={user.origen}
                    dia={user.dia}
                    mes={user.mes}
                    anio={user.anio}
                    direccion={user.direccion}
                    score={user.scores[0]?.valor ?? 0}
                    foto={user.foto}
                    primaryFuncion={seguir}
                    inVisitor={inVisitor}
                    seguidores={seguidores}
                    meSigue={meSigue}
                  />
                )
              ))}
            </div>
          </div>
        )}
        {seguidoresBool && <div className={filterBool ? styles.blockDesactive : styles.contBuscador}>
          <div className={styles.contInput}>
            <input className={styles.input} type="text" placeholder='Buscar por nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            <img className={styles.iconFilter} src={iconFilter} alt="" onClick={() => setFilterBool(true)}/>
          </div>
          <div className={styles.recomendados}>
            {seguidoresPorNombre.map((user) => (
              <Card key={user.id}
                status={"Seguidor"}
                id={user.id}
                nombre={user.nombre}
                posicion={user.posicion}
                origen={user.origen}
                dia={user.dia}
                mes={user.mes}
                anio={user.anio}
                direccion={user.direccion}
                score={user.scores[0]?.valor ?? 0}
                foto={user.foto}
                primaryFuncion={seguir}
                secundaryFuncion={dejarSeguir}
                inVisitor={inVisitor}
                seguidos={seguidos}
                meSigue={meSigue}
              />
            ))}
          </div>
        </div>}
        {seguidosBool && <div className={filterBool ? styles.blockDesactive : styles.contBuscador }>
          <div className={styles.contInput}>
            <input className={styles.input} type="text" placeholder='Buscar por nombre' onChange={(e) => setNombre(e.target.value)} value={nombre} />
            <img className={styles.iconFilter} src={iconFilter} alt="" onClick={() => setFilterBool(true)}/>
          </div>
          <div className={styles.recomendados}>
            {seguidosPorNombre.map((user) => (
              <Card key={user.id}
                status={"Seguido"}
                id={user.id}
                nombre={user.nombre}
                posicion={user.posicion}
                origen={user.origen}
                dia={user.dia}
                mes={user.mes}
                anio={user.anio}
                direccion={user.direccion}
                score={user.scores[0]?.valor ?? 0}
                foto={user.foto}
                primaryFuncion={dejarSeguir}
                inVisitor={inVisitor}
              />
            ))}
          </div>
        </div>}
      </div>
      {notificacionesBool === true && <ModalNotificaciones clickMessage={clickMessage} />}
      {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit} />}
    </div>
  );
};

export default Amigos;

