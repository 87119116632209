import React from 'react'
import styles from "./Card.module.css"
import userIcon from "../../assets/iconUser.png"
import { calcularEdad } from '../funciones'

const Card = ({ status, solicitudID, id, nombre, posicion, foto, dia, mes, anio, origen, direccion, score, aceptaTerminos, primaryFuncion, secundaryFuncion, inVisitor, meSigue, seguidores, seguidos }) => {
    console.log("nombre", nombre, "score", score)
    return (
        <div className={styles.container}>
            <div className={styles.contUser}>
                <img className={styles.foto} src={foto || userIcon} alt=""/>
                <div className={styles.user}>
                    <h3 className={styles.name}>{nombre}</h3>
                    <h4 className={styles.posicion}>{posicion} - {calcularEdad(dia, mes, anio)} años</h4>
                    <h4 className={styles.posicion}>{direccion}, {origen}</h4>
                </div>
            </div>
            <div className={styles.contBSD}>
                <p className={styles.score}>{score}</p>
                <p className={styles.BSDTxt}>BSD SCORE</p>
            </div>
            {status === "Seguidor" &&
                <div className={styles.botones}>
                    <button className={styles.btn} onClick={meSigue(seguidos, id) ? () => secundaryFuncion(id) : () => primaryFuncion(id)}>{meSigue(seguidos, id) ? "Dejar de seguir" : "Seguir tambien"}</button>
                    <button className={styles.btn} onClick={() => inVisitor(id)}>Ver perfil</button>
                </div>}
            {status === "Seguido" &&
                <div className={styles.botones}>
                    <button className={styles.btn} onClick={() => primaryFuncion(id)}>Dejar de seguir</button>
                    <button className={styles.btn} onClick={() => inVisitor(id)}>Ver perfil</button>
                </div>}
            {status === "Recomendadas" &&
                <div className={styles.botones}>
                    <button className={styles.btn} onClick={() => primaryFuncion(id)}>{meSigue(seguidores, id) ? "Seguir tambien" : "Seguir"}</button>
                    <button className={styles.btn} onClick={() => inVisitor(id)}>Ver perfil</button>
                </div>}
        </div>
    )
}

export default Card
