import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import styles from "./Score.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { getScoreByDate, getEntrenamientosByDate } from "../../../actions";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { setDataScore } from "../utils/setDataScore";
import { handleRangoChange } from "../utils/handleRangoChange";
//import { options } from "../utils/options";
import { generaDataEntrenamiento } from "../utils/generaDataEntrenamiento";
import { getlabels } from "../utils/labels";
import { generaDataLinea } from "../utils/generaDataLinea";
import { getDataLineas } from "../utils/getDataLineas";
import { generaDataConstancia } from "../utils/generaDataConstancia";
import { getTrainingAndMatchCount } from "../utils/getTrainingAndMatchCount";
import avatar from "../../../assets/PlayerAvatar.png";
import balonIcono from "../../../assets/balonIcono.png";
import porteria from "../../../assets/porteria.png";
import bandera from "../../../assets/bandera.png";
import tiempo from "../../../assets/IconoTiempo.png";
import { generarDataMetricas } from "../utils/generarDataMetricas";

// Registrar los componentes necesarios
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Score = ({
  user,
  getScoresByVisitor,
  getEntrenamientosByVisitor,
  isVisitor,
  openModalScore,
  setModalScoreBSD,
  entrenamientos,
  metricas
}) => {
  console.log(user)
  const dispatch = useDispatch();

  const scoreByDate = useSelector((state) => state.scoreByDate);
  const entrenamientosByDate = useSelector(
    (state) => state.entrenamientosByDate
  );

  const [arrayMetricas, setArrayMetricas] = useState(metricas);

  const [scoreByDateVisitor, setScoreByDateVisitor] = useState([]);
  const [entrenamientosByDateVisitor, setEntrenamientosByDateVisitor] =
    useState([]);

  const [data, setData] = useState({});
  const [dataNoventa, setDataNoventa] = useState([]);
  const [dataOchenta, setDataOchenta] = useState([]);
  const [dataSetenta, setDataSetenta] = useState([]);
  const [dataEntrenamiento, setDataEntrenamiento] = useState([]);
  const [dataConstancia, setDataConstancia] = useState([]);
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataMetricas, setDataMetricas] = useState(null);

  const dataEntrenamientoMatch = getTrainingAndMatchCount(entrenamientos);

  const [rangoFechas, setRangoFechas] = useState(() => {
    // Obtener fecha actual en la zona horaria local
    const fechaFin = new Date();
    const fechaInicio = new Date();

    // Ajustar a la zona horaria local
    const offset = fechaFin.getTimezoneOffset();
    fechaFin.setMinutes(fechaFin.getMinutes() - offset);
    fechaInicio.setMinutes(fechaInicio.getMinutes() - offset);

    // Configurar rango de 7 días
    fechaInicio.setDate(fechaInicio.getDate() - 7);

    // Configurar horas
    fechaInicio.setHours(0, 0, 0, 0);
    fechaFin.setHours(23, 59, 59, 999);

    return {
      inicio: fechaInicio.toISOString(),
      fin: fechaFin.toISOString(),
    };
  });



  const formatDateForInput = (isoString) => {
    const date = new Date(isoString);
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const newLabels = getlabels(rangoFechas);
      setLabels(newLabels);
      try {
        let payload = {
          inicio: rangoFechas.inicio,
          fin: rangoFechas.fin,
          userId: user.id,
        };

        if (isVisitor) {
          const scoreData = await getScoresByVisitor(payload);
          console.log(scoreData)
          const entrenamientosData = await getEntrenamientosByVisitor(payload);
          console.log(entrenamientosData)
          setScoreByDateVisitor(scoreData);
          setEntrenamientosByDateVisitor(entrenamientosData);
        } else {
          dispatch(getEntrenamientosByDate(payload));
          dispatch(getScoreByDate(payload));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [rangoFechas]);

  useEffect(() => {
    if (isVisitor) {
      setDataScore(scoreByDateVisitor, setData, labels);
      generaDataEntrenamiento(entrenamientosByDateVisitor, setDataEntrenamiento, labels);
      setDataConstancia(generaDataConstancia(entrenamientosByDateVisitor, labels));
    } else {
      setDataScore(scoreByDate, setData, labels);
      generaDataEntrenamiento(entrenamientosByDate, setDataEntrenamiento, labels);
      setDataConstancia(generaDataConstancia(entrenamientosByDate, labels));
    }

    // Agregar datos de métricas
    const metricasData = generarDataMetricas(metricas, labels);
    setDataMetricas(metricasData);

    console.log(metricasData)

    const dataNoventa = generaDataLinea(rangoFechas, 90);
    const dataOchenta = generaDataLinea(rangoFechas, 70);
    const dataSetenta = generaDataLinea(rangoFechas, 50);
    
    setDataNoventa(getDataLineas(dataNoventa, "noventa"));
    setDataOchenta(getDataLineas(dataOchenta, "setenta"));
    setDataSetenta(getDataLineas(dataSetenta, "cincuenta"));
  }, [scoreByDate, entrenamientosByDate, scoreByDateVisitor, entrenamientosByDateVisitor, metricas]);

  const handleChartClick = (event, elements, chart) => {
    if (!elements || elements.length === 0) return;
    
    const clickedIndex = elements[0].index;
    const clickedDate = labels[clickedIndex];
    
    if (clickedDate) {
      // Formatear la fecha antes de pasarla
      const [day, month, year] = clickedDate.split('/');
      const formattedDate = `${day}/${month}/${year}`;
      openModalScore(formattedDate);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          color: 'rgba(255, 255, 255, 0.8)',
          boxWidth: 15,
          padding: 10,
          font: {
            size: 11,
            family: "'Arial', sans-serif"
          },
          usePointStyle: false,
          boxHeight: 2,
        }
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        filter: function(tooltipItem) {
          return !['Destacado', 'Medio', 'Bajo'].includes(tooltipItem.dataset.label);
        },
        callbacks: {
          label: function(context) {
            if (context.dataset.type === 'bubble') {
              const point = context.raw;
              return `${point.nombre}: ${point.y}`;
            }
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(1);
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        min: 0,
        max: 100,
        ticks: {
          color: "white",
          stepSize: 2,
        },
      },
    },
    onClick: handleChartClick
  };

  const prepareDatasets = () => {
    return [
      {
        ...data,
        borderWidth: 2,
        pointRadius: 3
      },
      {
        ...dataNoventa,
        borderWidth: 1,
        borderDash: [5, 5],
        pointRadius: 0
      },
      {
        ...dataOchenta,
        borderWidth: 1,
        borderDash: [5, 5],
        pointRadius: 0
      },
      {
        ...dataSetenta,
        borderWidth: 1,
        borderDash: [5, 5],
        pointRadius: 0
      },
      ...(dataEntrenamiento || []).map(dataset => ({
        ...dataset,
        borderWidth: 2,
        pointRadius: 3
      })),
      {
        ...dataConstancia,
        borderWidth: 2,
        pointRadius: 3
      },
      ...(dataMetricas || [])
    ].filter(Boolean);
  };

  return (
    <div className={styles.conteiner}>
      <div className={styles.rangoFechas}>
        <h3 className={styles.title}>DESEMPEÑO GENERAL</h3>
        <p className={styles.tabText}>Periodo</p>
        <div className={styles.fechaInput}>
          <label>Desde:</label>
          <input
            type="date"
            value={formatDateForInput(rangoFechas.inicio)}
            onChange={(e) =>
              handleRangoChange("inicio", e.target.value, setRangoFechas)
            }
            max={formatDateForInput(new Date().toISOString())}
          />
        </div>

        <div className={styles.fechaInput}>
          <label>Hasta:</label>
          <input
            type="date"
            value={formatDateForInput(rangoFechas.fin)}
            onChange={(e) =>
              handleRangoChange("fin", e.target.value, setRangoFechas)
            }
            min={formatDateForInput(rangoFechas.inicio)}
            max={formatDateForInput(new Date().toISOString())}
          />
        </div>
        <p className={styles.btnCerrar} onClick={() => setModalScoreBSD(false)}>X</p>
      </div>
      <div className={styles.contenido}>
      <div className={styles.metricas}>
          <div className={styles.contData}>
            <div className={styles.contBSD}>
              <div className={styles.bsd}>
                <p className={styles.bsdText}>BSD SCORE</p>
                <p className={styles.bsdValor}>{user?.scores?.[0]?.valor ?? 0}</p>
              </div>
            </div>
            <img className={styles.avatar} src={avatar} alt="avatar" />
          </div>
          <div className={styles.contOtrasMetricas}>
            <p className={styles.subTitle}>OTRAS METRICAS</p>
            <div className={styles.contMetricas}>
              <img className={styles.icono} src={balonIcono} alt="balonIcono" />
              <div className={styles.contValores}>
                <p className={styles.txt}>GOLES TOTALES</p>
                <p className={styles.valor}>{dataEntrenamientoMatch.goles}</p>
              </div>
            </div>
            <div className={styles.contMetricas}>
              <img className={styles.icono} src={porteria} alt="porteria" />
              <div className={styles.contValores}>
                <p className={styles.txt}>PARTIDOS TOTALES</p>
                <p className={styles.valor}>{dataEntrenamientoMatch.partidos}</p>
              </div>
            </div>
            <div className={styles.contMetricas}>
              <img className={styles.icono} src={bandera} alt="bandera" />
              <div className={styles.contValores}>
                <p className={styles.txt}>SESIONES DE</p>
                <p className={styles.txt}>ENTRENAMIENTO</p>
                <p className={styles.valor}>{dataEntrenamientoMatch.entrenamientos}</p>
              </div>
            </div>
            <div className={styles.contMetricas}>
              <img className={styles.icono} src={tiempo} alt="tiempo" />
              <div className={styles.contValores}>
                <p className={styles.txt}>MINUTOS TOTALES</p>
                <p className={styles.valor}>{dataEntrenamientoMatch.tiempo}</p>
              </div>
            </div>
            <div className={styles.contMetricas}>
              <img className={styles.icono} src={tiempo} alt="tiempo" />
              <div className={styles.contValores}>
                <p className={styles.txt}>MINUTOS JUGADOS</p>
                <p className={styles.valor}>{dataEntrenamientoMatch.tiempoPartidos}</p>
              </div>
            </div>
          </div>
        </div>
      <div className={styles.scoreChart}>
        <Line
          data={{
            labels: labels,
            datasets: prepareDatasets()
          }}
          options={options}
        />
      </div>
      </div>
    </div>
  );
};

export default Score;
