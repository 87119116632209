import React from "react";
import styles from "../User.module.css";
import { formatoLegible } from "../../funciones.js";
import posicionOne from "../../../assets/PocisionOne.png";
import posicionTwo from "../../../assets/PocisionTwo.png";

const PosicionVisitor = ({
  user,
  userLocal,
  handlePosicion,
  posicionesLegibles,
}) => {

  return (
    <div className={styles.posicion}>
      <div className={styles.contTitle}>
        <h3 className={styles.seccion}>POSICIÓN DE JUEGO</h3>
        {userLocal.rol === "Jugador" && (
          <p className={styles.btnTxt} onClick={handlePosicion}>
            COMPARAR
          </p>
        )}
      </div>
      <div className={styles.contCampo}>
           {user.posicionesSeleccionadas && 
            user.posicionesSeleccionadas
              .filter(posicion => posicion !== null)
              .map((posicion, index) => (
                <img 
                  key={index}
                  className={styles[posicion]} 
                  src={posicionTwo} 
                  alt="" 
                />
              ))
           }
           {user.posicion && <img className={styles[user.posicion]} src={posicionOne} alt="" />}
      </div>
      <h3 className={styles.title}>POSICIÓN FAVORITA</h3>
      <div className={styles.contPosicion}>
        <div className={styles.posi}>
          <p className={styles.txt}>{formatoLegible(user.posicion)}</p>
        </div>
      </div>
      <h3 className={styles.title}>OTRAS POSICIONES</h3>
      <div className={styles.contPosicion}>
        <div className={styles.posi}>
          <p className={styles.txt}>{posicionesLegibles[0]}</p>
        </div>
        <div className={styles.posi}>
          <p className={styles.txt}>{posicionesLegibles[1]}</p>
        </div>
      </div>
    </div>
  );
};

export default PosicionVisitor;
